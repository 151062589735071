<template>
  <div class="history__orders">
    <div class="history__orders__title">
      <h4>Мои покупки</h4>
    </div>
    <div class="history__orders__data">
      <ul class="orders__title">
        <li
          v-for="order in orders_list"
          :key="order.order_title_id"
          @click="func_order_show(order, $event)"
        >
          <div class="title__order" :class="{ title__active: order.show }">
            <div class="title__order__info">
              {{
                "Заказ №" +
                order.order_title_id +
                " на сумму " +
                (order.price_all || 0) +
                "руб. в " +
                new Date(order.date_time).toLocaleDateString() +
                " " +
                new Date(order.date_time).toLocaleTimeString()
              }}
            </div>
            <div class="title__order__button">
              <button class="btn btn-info" @click="func_add_order_to_cart(order)">
                Добавить заказ в корзину
              </button>
            </div>
          </div>
          <div class="order__data" v-show="order.show && order.data.length > 0">
            <ul class="orders__data">
              <li
                v-for="data in order.data"
                :key="data.order_data_id"
                @click="$router.push('/product/' + data.product_id)"
              >
                <div class="data__img">
                  <img
                    :src="
                      data.img
                        ? '/img/products/' + data.img
                        : '/img/products/fallback.png'
                    "
                    alt=""
                  />
                </div>
                <div class="data">
                  <span>{{ "Наименование: " + (data.product_name || "") }}</span>
                  <span>{{ "Количество: " + (data.count_product || 0) }}</span>
                  <span>{{
                    "Цена: " + (data.new_price * data.count_product || 0) + "р"
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders_list: [],
    };
  },
  methods: {
    func_get_history_orders() {
      window
        .sendRequest({
          func: "store_get_orders",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.orders_list = result;
          }
        });
    },
    func_add_order_to_cart(order) {
      if (order) {
        this.$confirm("Переместить товары из заказа в корзину?")
          .then((answer) => {
            if (answer) {
              let cart = localStorage.getItem("cart")
                ? JSON.parse(localStorage.getItem("cart"))
                : [];
              if (!cart || cart.length === 0) {
                cart = order.data;
              } else {
                order.data.forEach((item) => {
                  if (item) {
                    let obj = cart.find(
                      (x) => Number(x.product_id) === Number(item.product_id)
                    );
                    if (!obj) cart.push(item);
                  }
                });
              }
              if (cart && cart.length > 0) {
                localStorage.setItem("cart", JSON.stringify(cart));
                this.$store.commit("CHANGE_CART", cart);
                this.$notify({
                  group: "foo",
                  title: "Информация",
                  text: "Товары добавлены в корзину",
                  type: "info",
                });
              }
            }
          })
          .catch(() => {});
      }
    },
    func_order_show(order, e) {
      if (order && e.target.type !== "submit") {
        order.show = !order.show;
      }
    },
  },
  mounted() {
    this.func_get_history_orders();
  },
};
</script>

<style lang="scss">
.history__orders {
  .history__orders__title {
    padding-bottom: 5px;
    border-bottom: 3px solid var(--green);
    h4 {
      text-transform: uppercase;
      font-size: 18px;
      color: var(--green);
      font-weight: 700;
    }
  }
  .history__orders__data {
    .orders__title {
      list-style-type: none;
      li {
        margin-top: 5px;
        background: #ececec;
        cursor: pointer;
        &:hover .title__order {
          background: var(--green);
          color: #fff;
          transition: 0.5s;
        }
        .title__order {
          padding: 5px;
          transition: 0.5s;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title__order__button {
            padding-right: 5px;
          }
        }
      }
      .orders__data {
        list-style-type: none;
        li {
          padding: 5px;
          display: flex;
          background: #fff;
          .data__img {
            width: 100px;
            padding: 10px;
            img {
              display: flex;
              object-fit: cover;
              object-position: center;
              width: 100%;
              height: 100%;
            }
          }
          .data {
            span {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
}
.title__active {
  background: var(--green);
  color: #fff;
}
</style>
