<template>
  <div class="modal__wrapper">
    <transition name="show_modal">
      <Auth
        v-if="modal_list.find((item) => item.module === 'auth').status"
        @close="func_close_modal()"
      />
      <Register
        v-if="modal_list.find((item) => item.module === 'register').status"
        @close="func_close_modal()"
      />
    </transition>
  </div>
</template>

<script>
import Auth from "@/components/modals/v-auth.vue";
import Register from "@/components/modals/v-register.vue";
export default {
  props: {
    modal_module: String,
  },
  components: {
    Auth,
    Register,
  },
  data() {
    return {
      show_modal: false,
      modal_list: [
        { module: "auth", status: false },
        {
          module: "register",
          status: false,
        },
      ],
    };
  },
  methods: {
    func_start_modal(modal_module) {
      this.modal_list.forEach((item) => {
        if (item.module === modal_module) {
          item.status = true;
        }
      });
    },
    func_close_modal(modal_module) {
      this.modal_list.forEach((item) => {
        if (item.module === modal_module) {
          item.status = false;
        }
      });
      this.$store.commit("CHANGE_MODAL", {
        show: false,
        module: null,
      });
    },
  },
  mounted() {
    if (this.modal_module) {
      this.func_start_modal(this.modal_module);
    }
  },
};
</script>

<style lang="scss">
.show_modal-enter-active,
.show_modal-leave-active {
  transition: opacity 0.5s;
}
.show_modal-enter,
.show_modal-leave-to {
  opacity: 0;
}
.modal {
  display: block !important;
  background: rgb(0 0 0 / 66%);
}

.modal-header {
  .close {
    outline: none;
  }
}
</style>