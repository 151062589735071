<template>
  <div class="news__wrapper">
    <div class="news__content">
      <div
        class="news__item"
        v-for="news in news_list"
        :key="news.new_id"
        @click="func_show_news(news.new_id)"
        :style="{
          'background-image': 'url(../img/news/' + news.img + ')',
        }"
      >
        <div class="news__item__description">
          <div class="front">
            {{ news.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news_list: [],
    };
  },
  methods: {
    func_get_data() {
      window
        .sendRequest({
          func: "store_get_news_limit",
          auth: false,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.news_list = result;
          }
        });
    },
    func_show_news(new_id) {
      if (new_id) {
        this.$router.push("/news");
        this.$route.params.new_id = new_id;
      }
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style lang="scss">
.news__wrapper {
  .news__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 60vh;
    .news__item {
      flex-basis: 33.33%;
      height: 60vh;
      flex-wrap: wrap;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      will-change: transform;
      transition: 0.3s;
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        background: rgb(34 34 34 / 20%);
      }
      &:hover {
        transform: translateY(-5px);
      }
      .news__item__description {
        transition: 0.8s;
        width: 80%;
        position: absolute;
        height: 100px;
        background: rgb(34 34 34 / 75%);
        color: #fff;
        padding: 25px;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
    }
  }
}
</style>
