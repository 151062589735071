import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        progress: false,
        user: {},
        cart: [],
        favorites: [],
        modal: {
            show: false,
            module: null
        },
        catalog_select: {},
        search: ''

    },
    mutations: {
        CHANGE_PROGRESS: (state, progress) => {
            state.progress = progress
        },
        CHANGE_USER: (state, user) => {
            state.user = user
        },
        CHANGE_CART: (state, cart) => {
            state.cart = cart
        },
        CHANGE_FAVORITES: (state, favor) => {
            state.favorites = favor
        },
        CHANGE_MODAL: (state, modal) => {
            state.modal = modal
        },
        CHANGE_CATALOG_SELECT: (state, select) => {
            state.catalog_select = select
        },
        CHANGE_SEARCH: (state, search) => {
            state.search = search
        },
    },
    actions: {
        TOGGLE_PROGRESS({ commit }, progress) {
            commit('CHANGE_PROGRESS', progress)
        },
        TOGGLE_USER({ commit }, user) {
            commit('CHANGE_USER', user)
        },
        TOGGLE_CART({ commit }, cart) {
            commit('CHANGE_CART', cart)
        },
        TOGGLE_FAVORITES({ commit }, favor) {
            commit('CHANGE_FAVORITES', favor)
        },
        TOGGLE_MODAL({ commit }, modal) {
            commit('CHANGE_FAVORITES', modal)
        },
        TOGGLE_CATALOG_SELECT({ commit }, select) {
            commit('CHANGE_CATALOG_SELECT', select)
        },
        TOGGLE_SEARCH({ commit }, search) {
            commit('CHANGE_SEARCH', search)
        },
    },
    getters: {
        PROGRESS_STATE(state) {
            return state.progress;
        },
        USER_STATE(state) {
            return state.user;
        },
        CART_STATE(state) {
            return state.cart;
        },
        FAVORITES_STATE(state) {
            return state.favorites;
        },
        MODAL_STATE(state) {
            return state.modal;
        },
        CATALOG_SELECT_STATE(state) {
            return state.catalog_select;
        },
        SEARCH_STATE(state) {
            return state.search;
        }
    }
});

export default store;