<template>
  <div class="right__menu">
    <div class="right__menu_close">
      <div class="icon_cart" v-if="cart">
        <v-icon name="cart-plus" scale="1.5" />
      </div>
      <div class="icon_favor" v-if="!cart">
        <v-icon name="star" scale="1.5" />
      </div>
      {{ this.cart ? "Корзина" : "Избранное" }}
      <button @click="$emit('close_right_menu')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="right__menu__wrapper">
      <ul class="list_cart" v-if="cart">
        <li v-for="cart in CART_STATE" :key="cart.product_id">
          <div class="product">
            <div class="img">
              <img
                :src="
                  cart.img ? '/img/products/' + cart.img : '/img/products/fallback.png'
                "
                alt=""
              />
            </div>
            <div class="title">
              <div>{{ cart.product_name }}</div>
              <div class="cart__count">
                Кол-во:
                <input
                  type="number"
                  min="1"
                  max="10"
                  @change="func_add_count(cart)"
                  v-model="cart.count"
                />
              </div>
              <div>{{ (cart.count || 1) * cart.new_price + " руб." }}</div>
            </div>
          </div>
          <div class="del">
            <button
              class="btn btn-light"
              @click="func_del_product(true, cart)"
              title="Удалить"
            >
              <v-icon name="trash" />
            </button>
          </div>
        </li>
      </ul>
      <ul class="list_cart" v-if="!cart">
        <li v-for="favor in FAVORITES_STATE" :key="favor.product_id">
          <div class="product">
            <div class="img">
              <img
                :src="
                  favor.img ? '/img/products/' + favor.img : '/img/products/fallback.png'
                "
                alt=""
              />
            </div>
            <div class="title">
              {{ favor.product_name }}
            </div>
          </div>
          <div class="del">
            <button
              class="btn btn-success"
              @click="func_copy_product(favor)"
              title="В корзину"
            >
              <v-icon name="cart-plus" />
            </button>
            <button
              class="btn btn-light"
              @click="func_del_product(false, favor)"
              title="Удалить"
            >
              <v-icon name="trash" />
            </button>
          </div>
        </li>
      </ul>
      <p class="message__cart" v-if="cart && CART_STATE.length === 0">
        Нет товаров в корзине
      </p>
      <p class="message__cart" v-if="!cart && FAVORITES_STATE.length === 0">
        Нет товаров в избранном
      </p>
    </div>
    <div class="right__menu__info" v-if="cart && CART_STATE.length > 0">
      <div class="info__text">
        <ul>
          <li>
            Кол-во товаров:
            <span>{{ cart ? CART_STATE.length : FAVORITES_STATE.length }}</span>
          </li>
          <li>
            Итого: <span>{{ cart ? summ_cart : 0 }} руб.</span>
          </li>
        </ul>
      </div>
      <div class="info__button">
        <button
          class="btn btn-success"
          @click="func_order"
          v-show="auth"
          :disabled="$router.history.current.name === 'order'"
        >
          Оформить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    cart: Boolean,
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
    ...mapGetters(["FAVORITES_STATE"]),
    ...mapGetters(["USER_STATE"]),
  },

  data() {
    return {
      summ_cart: 0,
      summ_favor: 0,
      auth: localStorage.getItem("user") ? true : false,
    };
  },

  methods: {
    func_del_product(cart_bool, product) {
      if (product) {
        if (cart_bool) {
          let cart = [...this.CART_STATE];
          if (cart.length > 0) {
            let obj = cart.find((item) => item.product_id === product.product_id);
            let index = cart.indexOf(obj);
            if (index !== -1) {
              cart.splice(index, 1);
              this.$store.commit("CHANGE_CART", cart);
              localStorage.setItem("cart", JSON.stringify(cart));
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар удален из корзины",
                type: "info",
              });
            }
          }
        } else {
          let favor = [...this.FAVORITES_STATE];
          if (favor.length > 0) {
            let obj = favor.find((item) => item.product_id === product.product_id);
            let index = favor.indexOf(obj);
            if (index !== -1) {
              favor.splice(index, 1);
              this.$store.commit("CHANGE_FAVORITES", favor);
              localStorage.setItem("favor", JSON.stringify(favor));
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар удален из избанного",
                type: "info",
              });
            }
          }
        }
      }
    },
    func_calc_summ() {
      let summ = 0;
      if (this.cart) {
        let cart = [...this.CART_STATE];
        if (cart.length > 0) {
          cart.forEach((item) => {
            summ += Number(item.new_price) * Number(item.count || 1);
          });
          this.summ_cart = summ;
        }
      } else {
        let favor = [...this.FAVORITES_STATE];
        if (favor.length > 0) {
          favor.forEach((item) => {
            summ += Number(item.new_price) * Number(item.count || 1);
          });
          this.summ_favor = summ;
        }
      }
    },
    func_copy_product(product) {
      let cart = [...this.CART_STATE];
      let favor = [...this.FAVORITES_STATE];
      let obj_find = cart.find((item) =>
        Number(item.product_id === Number(product.product_id))
      );
      if (obj_find) {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Товар уже есть в корзине",
          type: "warn",
        });
        return;
      }
      product.count = 1;
      cart.push(product);

      let index = favor.indexOf(product);
      if (index !== -1) {
        favor.splice(index, 1);
      }

      this.$store.commit("CHANGE_FAVORITES", favor);
      localStorage.setItem("favor", JSON.stringify(favor));

      this.$store.commit("CHANGE_CART", cart);
      localStorage.setItem("cart", JSON.stringify(cart));
      this.$notify({
        group: "foo",
        title: "Предупрждение",
        text: "Товар добавлен в корзину",
        type: "info",
      });
    },
    func_order() {
      if (this.$router.history.current.name !== "order") this.$router.push("/order");
    },
    func_add_count(product) {
      let cart = [...this.CART_STATE];
      cart.forEach((item) => {
        if (Number(product.product_id) === Number(item.product_id)) {
          item.count = Number(product.count);
        }
      });
      this.$store.commit("CHANGE_CART", cart);
      localStorage.setItem("cart", JSON.stringify(cart));
    },
  },
  updated() {
    this.auth = localStorage.getItem("user") ? true : false;
    this.func_calc_summ();
  },
};
</script>

<style lang="scss">
.cart__count {
  display: flex;
  width: 100%;
  input {
    margin-left: 5px;
    border: none;
    padding: 1px;
    text-align: center;
    font-weight: 700;
  }
}
.right__menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  box-shadow: -1px -4px 20px 0px #565656;
  z-index: 9999;
  .right__menu_close {
    width: 100%;
    flex-basis: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: var(--red);
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    .icon_cart,
    .icon_favor {
      font-size: 20px;
    }
    button {
      border: 0;
      background: transparent;
      transition: 0.5s;
      color: #fff;
      &:focus {
        border: 0;
        outline: none;
      }
      svg {
        transition: 0.5s;
      }
      &:hover svg {
        opacity: 0.8;
        transition: 0.5s;
      }
    }
  }
  .message__cart {
    padding: 10px;
  }
  .right__menu__wrapper {
    flex-basis: 80%;
    .list_cart {
      list-style-type: none;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        transition: 0.5s;
        font-size: 13px;
        &:hover {
          background: var(--green);
          color: #fff;
        }
        .del {
          display: flex;
          button {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
        .product {
          display: flex;
          align-items: center;
          width: 100%;
          .img {
            width: 25%;
            height: 50px;
            margin-right: 5px;
            img {
              display: flex;
              object-fit: cover;
              object-position: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .right__menu__info {
    flex-basis: 10%;
    background: var(--red);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .info__text {
      ul {
        list-style-type: none;
      }
    }
  }
}
</style>
