<template>
  <div>
    <div class="comments__wrapper">
      <div class="comment_add" v-if="add_comment">
        <h5>Добавить комментарий</h5>
        <form @submit.prevent="func_save_comment">
          <div class="form-group">
            <label>Текст комментария</label>
            <textarea required v-model="comment_text" class="form-control"></textarea>
          </div>
          <button class="btn btn-info">Добавить</button>
        </form>
      </div>
      <div v-if="comment_list.length > 0">
        <h5>
          Комментарии
          <span v-if="comment_list.length > 0">({{ comment_list.length }})</span>
        </h5>
        <div
          class="comment__item"
          v-for="comment in comment_list"
          :key="comment.comment_id"
        >
          <div class="user__img">
            <img src="../assets/img/no_photo.png" alt="" />
          </div>
          <div class="comment__content">
            <div class="comment__header">
              <div class="comment__login">{{ comment.login }}</div>
              <div class="comment__date">
                {{
                  new Date(comment.date_time).toLocaleDateString() +
                  " " +
                  new Date(comment.date_time).toLocaleTimeString()
                }}
              </div>
            </div>
            <div class="comment__text">{{ comment.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { product_id: Number },
  data() {
    return {
      comment_list: [],
      add_comment: false,
      comment_text: "",
    };
  },
  watch: {
    product_id: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      this.func_get_comment();
    },
  },
  methods: {
    func_get_comment() {
      this.comment_list = [];
      window
        .sendRequest({
          func: "store_get_product_comment",
          auth: localStorage.getItem("user") ? true : false,
          params: {
            product_id: this.product_id,
          },
        })
        .then((result) => {
          if (result) {
            if (result.comments && result.comments.length > 0) {
              this.comment_list = result.comments.sort((a, b) => {
                if (a.date_time < b.date_time) {
                  return 1;
                } else {
                  return -1;
                }
              });
            }
            if (result.add_comment) this.add_comment = result.add_comment;
          }
        });
    },
    func_save_comment() {
      window
        .sendRequest({
          func: "store_set_product_comment",
          auth: true,
          params: {
            product_id: this.product_id,
            comment: this.comment_text,
          },
        })
        .then((result) => {
          if (result) {
            this.comment_list.unshift(result);
            this.comment_text = "";
            this.add_comment = false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.comments__wrapper {
  .comment__item {
    box-shadow: 0 0 2px grey;
    margin-top: 15px;
    display: flex;
    .user__img {
      flex-basis: 15%;
      img {
        width: 100%;
      }
    }
    .comment__content {
      padding: 10px;
      flex-basis: 85%;
      .comment__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .comment__login {
          font-weight: 700;
        }
        .comment__date {
          font-size: 13px;
        }
      }

      .comment__text {
        font-size: 13px;
      }
    }
    transition: 0.5s;
    &:hover {
      background: #f2f2f2;
    }
  }
}
</style>
