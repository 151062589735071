<template>
  <div id="app">
    <notifications group="foo" position="top left" />
    <Progress />
    <router-view></router-view>
    <Modal v-if="MODAL_STATE.show" :modal_module="MODAL_STATE.module" />
  </div>
</template>

<script>
import Modal from "@/components/v-modal.vue";
import Progress from "@/components/v-progress.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Modal,
    Progress,
  },
  mounted() {
    //Проверка корзины
    if (localStorage.getItem("cart")) {
      this.$store.commit("CHANGE_CART", JSON.parse(localStorage.getItem("cart")));
    }
    //Проврка избранного
    if (localStorage.getItem("favor")) {
      this.$store.commit("CHANGE_FAVORITES", JSON.parse(localStorage.getItem("favor")));
    }
    //Проверка авторизации
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      window
        .sendRequest({
          func: "store_check_token",
          auth: false,
          params: {
            user_id: user.user_id,
            token: user.token,
          },
        })
        .then((result) => {
          if (result && Number(result.code) !== 1) {
            this.$store.commit("CHANGE_USER", JSON.parse(localStorage.getItem("user")));
          }
        });
    }
  },
  computed: {
    ...mapGetters(["MODAL_STATE"]),
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
@import "style/main.scss";
@import "style/media.scss";
</style>
