<template>
  <div class="catalog__menu">
    <div class="catalog_menu_close">
      Каталог
      <button @click="$emit('close_catalog_menu')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="catalog__menu__wrapper">
      <CatalogDrop  />
    </div>
  </div>
</template>

<script>
import CatalogDrop from "@/components/v-catalog-drop.vue";

export default {
  components: {
    CatalogDrop,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.catalog__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: #fff;
  box-shadow: -1px -4px 20px 0px #565656;
  z-index: 9999;
  .catalog_menu_close {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: var(--red);

    font-weight: 700;
    font-size: 18px;
    color: #fff;
    button {
      border: 0;
      background: transparent;
      transition: 0.5s;
      color: #fff;
      &:focus {
        border: 0;
        outline: none;
      }
      svg {
        transition: 0.5s;
      }
      &:hover svg {
        opacity: 0.8;
        transition: 0.5s;
      }
    }
  }
}
</style>