<template>
  <div class="products__order__wrapper">
    <h4>Товары под заказ</h4>
    <div class="products_order__table">
      <table class="table">
        <thead>
          <tr>
            <th>ID товара</th>
            <th>Наименование</th>
            <th>Артикул</th>
            <th>Тип</th>
            <th>Пользователь</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.product_id">
            <td>{{ product.product_id }}</td>
            <td>{{ product.product_name }}</td>
            <td>{{ product.article }}</td>
            <td>{{ product.order ? "Под заказ" : "В наличии" }}</td>
            <td>{{ product.user }}</td>
            <td>
              <select v-model="product.status_id" @change="func_select(product)">
                <option
                  v-for="status in status_list"
                  :key="status.status_id"
                  :value="status.status_id"
                >
                  {{ status.status }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      status_list: [],
    };
  },
  methods: {
    func_get_products_order() {
      window
        .sendRequest({
          func: "store_get_products_order_all",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            if (result.products) this.products = result.products;
            if (result.status_list) this.status_list = result.status_list;
          }
        });
    },
    func_select(product) {
      if (product) {
        window
          .sendRequest({
            func: "store_set_products_order_status",
            auth: true,
            params: {
              product_id: product.product_id,
              status_id: product.status_id,
              user_add_id: product.user_id,
            },
          })
          .then((result) => {
            if (result && result.status) {
              this.$notify({
                group: "foo",
                title: "Информация",
                text: "Статус товара изменен",
                type: "info",
              });
            }
          });
      }
    },
  },
  mounted() {
    this.func_get_products_order();
  },
};
</script>

<style></style>
