<template>
  <div>
    <Header />
    <CatalogRow :catalog="true" />
    <div class="news">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h5>Оформление заказа</h5>
            <div class="order__wrapper" v-if="!order">
              <div class="products__list">
                <ul>
                  <li
                    v-for="product in products"
                    :key="product.product_id"
                    :class="{ isOrder: product.order }"
                  >
                    <div class="product__item">
                      <div class="product__img">
                        <img
                          :src="
                            product.img
                              ? '/img/products/' + product.img
                              : '/img/fallback.png'
                          "
                          alt=""
                        />
                      </div>
                      <div class="product__info">
                        <div class="info__item">
                          <i>Наименование: </i>
                          {{ product.product_name }}
                        </div>
                        <div class="info__item">
                          <i>Артикул: </i>
                          {{ product.article }}
                        </div>
                        <div class="info__item">
                          <i>Кол-во: </i>
                          {{ Number(product.count) }}
                        </div>
                        <div class="info__item">
                          <i>Цена: </i>
                          {{
                            Number(product.new_price * Number(product.count)).toFixed(2)
                          }}
                          руб.
                        </div>
                        <div class="info__item">
                          <i>Тип: </i>
                          {{ product.order ? "Под заказ" : "В наличии" }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="products__itog">
                  <p>
                    Сумма заказа: <span>{{ func_get_summ() }}</span> руб.
                  </p>
                  <p v-show="sale">
                    Скидка составила:
                    <span
                      >{{ Number((func_get_summ() * sale) / 100).toFixed(2) }} руб.</span
                    >
                  </p>
                  <p v-show="sale">
                    Сумма заказа с учетом скидки:
                    <span
                      >{{
                        Number(func_get_summ() - (func_get_summ() * sale) / 100).toFixed(
                          2
                        )
                      }}
                      руб.</span
                    >
                  </p>
                </div>
                <div class="delivery__wrapper">
                  <div class="form-group delivery__group">
                    <input
                      type="radio"
                      class="form-control delivery__radio"
                      name="delivery"
                      id="delivery_s"
                      :value="false"
                      v-model="delivery"
                    />
                    <label for="delivery_s"
                      >Самовывоз (г. Балаково, ул. Московская, д. 79)</label
                    >
                  </div>
                  <div class="form-group delivery__group">
                    <input
                      type="radio"
                      class="form-control delivery__radio"
                      name="delivery"
                      id="delivery"
                      :value="true"
                      v-model="delivery"
                    />
                    <label for="delivery">Доставка</label>
                  </div>
                  <div class="delivery__adress" v-if="delivery">
                    <Delivery @delivery_id="func_set_delivery_id" />
                  </div>
                  <div class="promo">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Промокод"
                      v-model="promo"
                    />
                    <button class="btn btn-success" @click="func_check_promo">
                      Проверить промокод
                    </button>
                    <div class="result" v-show="promo_msg">
                      <span v-html="promo_msg"></span>
                    </div>
                  </div>
                </div>
                <div class="itog__button">
                  <button
                    class="btn btn-warning"
                    :disabled="user_info.balance < func_get_summ()"
                    @click="func_order"
                  >
                    Купить
                  </button>

                  <div
                    class="info__warn"
                    v-if="user_info.balance < func_get_summ() && !sale"
                  >
                    Не достаточно средств ({{
                      Number(func_get_summ() - user_info.balance).toFixed(2)
                    }}
                    руб.)
                  </div>
                  <div
                    class="info__warn"
                    v-if="user_info.balance < func_get_summ() && sale"
                  >
                    Не достаточно средств ({{
                      Number(
                        Number(func_get_summ() - (func_get_summ() * sale) / 100) -
                          user_info.balance
                      ).toFixed(2)
                    }}
                    руб.)
                  </div>
                </div>
              </div>
              <div class="user__info">
                <div class="user__info__item">
                  <i>Текущий баланс: </i>{{ Number(user_info.balance).toFixed(2) }} руб.
                </div>
                <div class="user__info__item">
                  <i>Количество балов: </i>{{ user_info.bal }}
                </div>
                <button
                  class="btn btn-info"
                  v-if="user_info.balance < func_get_summ()"
                  @click="$router.push('/pay')"
                >
                  Пополнить баланс
                </button>
              </div>
            </div>
            <div class="order__save" v-if="order">
              <div class="save">
                <div class="save__img">
                  <img src="@/assets/img/logo_png_160px.png" alt="" />
                </div>
                <div class="save__text">
                  <p>Спасибо за покупку, ожидайте с Вами обязательно свяжутся</p>
                  <router-link to="/">Продолжить покупки</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";
import Delivery from "@/components/v-delivery.vue";

export default {
  components: {
    Header,
    CatalogRow,
    Footer,
    Delivery,
  },
  data() {
    return {
      products: [],
      delivery: false,
      delivery_id: null,
      order: false,
      promo_msg: "",
      promo: "",
      sale: 0,
      setting: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      delivery_info: {
        city: "",
        index: "",
      },
      user_info: {
        balance: 0,
        bal: 0,
      },
    };
  },
  methods: {
    func_check_promo() {
      if (this.promo) {
        window
          .sendRequest({
            func: "store_check_promo",
            auth: true,
            params: {
              promo: this.promo,
            },
          })
          .then((result) => {
            if (result) {
              if (result.sale) {
                this.sale = Number(result.sale);
                this.promo_msg =
                  '<span style="color:green;">' + result.msg + result.sale + "%</span>";
                this.promo = "";
              } else {
                this.promo_msg = '<span style="color:red;">' + result.msg + "</span>";
              }
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Информация",
          text: "Введите промокод",
          type: "warn",
        });
      }
    },
    func_set_delivery_id(delivery_id) {
      this.delivery_id = delivery_id;
    },
    func_get_product() {
      this.func_get_user_info();
      let products = JSON.parse(localStorage.getItem("cart")) || [];
      if (products.length > 0) this.products = products;
    },
    func_get_user_info() {
      window
        .sendRequest({
          func: "store_get_user_balance",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.user_info = result;
          }
        });
    },
    func_get_summ() {
      let summ = 0;
      this.products.forEach((item) => {
        if (item) {
          summ += item.new_price ? Number(item.new_price) : 0;
        }
      });
      return Number(summ).toFixed(2);
    },
    func_check_order() {
      let bool = false;
      this.products.forEach((item) => {
        if (item && item.order) {
          bool = true;
        }
      });
      return bool;
    },
    func_order() {
      if (this.func_check_order()) {
        this.$confirm("В заказе присутсвую товары, которых нет в наличии, заказать?")
          .then((answer) => {
            if (answer) {
              let products = this.products.filter((x) => x.order === true);
              window
                .sendRequest({
                  func: "store_set_products_order",
                  auth: true,
                  params: {
                    products,
                  },
                })
                .then((result) => {
                  if (result.status) {
                    let cart = JSON.parse(localStorage.getItem("cart") || []);
                    let new_cart = [];
                    if (cart) {
                      cart.forEach((item) => {
                        if (item.order !== true) {
                          new_cart.push(item);
                        }
                      });
                    }
                    localStorage.setItem("cart", JSON.stringify(new_cart));
                    this.func_get_product();
                    this.$store.commit("CHANGE_CART", new_cart);
                    this.$notify({
                      group: "foo",
                      title: "Информация",
                      text:
                        "Товары 'Под заказ' были заказаны, как только товары будут в наличии, мы Вас оповестим",
                      type: "info",
                    });
                  }
                });
            }
          })
          .catch(() => {});
      } else {
        if (this.user_info.balance >= this.func_get_summ()) {
          if (this.delivery) {
            if (this.delivery_id) {
              this.func_save_order_data();
            } else {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Выберите адрес доставки",
                type: "warn",
              });
            }
          } else {
            this.func_save_order_data();
          }
        } else {
          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Не достаточно средств, пополните счет",
            type: "warn",
          });
        }
      }
    },
    func_save_order_data() {
      window
        .sendRequest({
          func: "store_set_order",
          auth: true,
          params: {
            products: this.products,
            delivery_id: this.delivery_id,
            delivery: this.delivery,
          },
        })
        .then((result) => {
          if (result && result.status) {
            localStorage.setItem("cart", []);
            this.$store.commit("CHANGE_CART", []);
            this.order = true;
          }
        });
    },
  },
  mounted() {
    if (!localStorage.getItem("cart")) this.$router.push("/");
    this.func_get_product();
  },
};
</script>

<style lang="scss" scoped>
.isOrder {
  color: #fff;
  background: var(--red);
}
.delivery__group {
  label {
    margin: 0;
  }
  .delivery__radio {
    width: 25px;
    margin-right: 5px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  display: flex;
  align-items: center;
}

.order__wrapper {
  display: flex;
  .products__list {
    flex-basis: 70%;
    ul {
      list-style-type: none;
      li {
        margin-bottom: 10px;
        padding: 10px;
        border-bottom: 3px solid var(--green);
        .product__item {
          display: flex;
          .product__img {
            width: 20%;
            margin-right: 10px;
            img {
              display: flex;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
    .products__itog {
      span {
        font-weight: 700;
      }
      font-size: 18px;
      margin-bottom: 25px;
    }
    .itog__button {
      display: flex;
      align-items: center;
      color: red;
      button {
        margin-right: 5px;
      }
      margin-bottom: 10px;
    }
  }
  .user__info {
    flex-basis: 30%;
    text-align: right;
    font-weight: 700;
    i {
      font-weight: normal;
    }
    button {
      margin-top: 10px;
    }
  }
}
.order__save {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .save {
    text-align: center;
    .save__text {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
.info__warn {
  margin-left: 10px;
}
.promo {
  display: flex;
  align-items: center;
  margin: 10px 0;
  span {
    margin-left: 10px;
  }
  input {
    width: 20%;
  }
  button {
    margin-left: 5px;
  }
}
</style>
