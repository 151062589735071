<template>
  <div class="news__list__wrapper">
    <div class="news__list__table" v-if="!add_news && !selected_news">
      <h4>Список новостей</h4>
      <button class="btn btn-info" @click="add_news = !add_news">
        Добавить новую новость
      </button>
      <table class="table">
        <thead>
          <tr>
            <th>ID новости</th>
            <th>Изображение</th>
            <th>Загаловок</th>
            <th>Дата и время</th>
            <th>Удален</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="news in news_list"
            :key="news.new_id"
            @click="func_selected_news(news, $event)"
          >
            <td>{{ news.new_id }}</td>
            <td class="img_table">
              <img
                :src="news.img ? '/img/news/' + news.img : '/img/fallback.png'"
                alt=""
              />
            </td>
            <td>{{ news.title }}</td>
            <td>
              {{
                new Date(news.date_time).toLocaleDateString() +
                " " +
                new Date(news.date_time).toLocaleTimeString()
              }}
            </td>
            <td>{{ news.disable ? "Да" : "Нет" }}</td>
            <td>
              <button class="btn btn-danger" @click="func_disable_news(news)">
                {{ news.disable ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddNewNews
      :news_data="selected_news || null"
      v-if="add_news || selected_news"
      @back="func_back"
      @save="func_save_new_news"
    />
  </div>
</template>

<script>
import AddNewNews from "@/components/v-add-new-news.vue";
export default {
  components: {
    AddNewNews,
  },
  data() {
    return {
      add_news: false,
      news_list: [],
      selected_news: null,
    };
  },
  methods: {
    func_get_news_list() {
      window
        .sendRequest({
          func: "store_get_news_list",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.news_list = result;
          }
        });
    },
    func_save_new_news() {
      this.add_news = false;
      this.selected_news = null;
      this.func_get_news_list();
    },
    func_disable_news(news) {
      window
        .sendRequest({
          func: "store_set_disable_news",
          auth: true,
          params: {
            new_id: news.new_id,
            disable: !news.disable,
          },
        })
        .then((result) => {
          if (result && result.status) {
            news.disable = !news.disable;
            this.$notify({
              group: "foo",
              title: "Информация",
              text: "Выполнено успешно",
              type: "info",
            });
          }
        });
    },
    func_selected_news(news, e) {
      if (e.target.type === "submit") return;
      this.selected_news = news;
    },
    func_back() {
      this.add_news = false;
      this.selected_news = null;
    },
  },
  mounted() {
    this.func_get_news_list();
  },
};
</script>

<style lang="scss">
.news__list__wrapper {
  .table {
    tr {
      td.img_table {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
          display: flex;
          object-fit: cover;
          object-position: cente6r;
        }
      }
    }
  }
}
</style>
