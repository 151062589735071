import Vue from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import store from './store.js'
import router from './router/router.js'
import Notifications from 'vue-notification'
import Vuetify from 'vuetify'
import 'vue-awesome/icons/flag'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import VueEditor from 'vue2-editor'
import Quill from 'vue2-editor'
import VueSimpleAlert from "vue-simple-alert";

import request from '@/request.js';

Vue.component('v-icon', Icon);

Vue.use(Notifications);
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    BootstrapVue,
    IconsPlugin,
    request,
    axios,
    store,
    router,
    Vuetify,
    Notifications,
    VueEditor,
    Quill,
    VueSimpleAlert,
    'v-icon': Icon
}).$mount('#app')