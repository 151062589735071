<template>
  <div>
    <div class="user">
      <button
        v-if="USER_STATE.login"
        class="btn btn-light"
        @click="func_show_lk"
      >
        <v-icon name="user" scale="1" /> {{ USER_STATE.login }}
      </button>
      <button v-else class="btn btn-light" @click="func_show_modal('auth')">
        Войти
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
  methods: {
    func_show_lk() {
      if (this.$route.name !== "account") this.$router.push("/account");
    },
    func_show_modal(module) {
      this.$store.commit("CHANGE_MODAL", {
        show: true,
        module: module,
      });
    },
  },
};
</script>

<style>
</style>