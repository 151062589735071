<template>
  <div>
    <Header />
    <CatalogRow :catalog="true" />
    <SettingUser v-if="setting_show" @back="func_back_setting" />
    <div class="account" v-if="!setting_show">
      <div class="container">
        <div class="row">
          <div class="title__account">
            <h4>Личный кабинет</h4>
            <div class="buttons">
              <button
                v-if="user && user.user_type_id === 5"
                @click="func_show_admin"
                class="btn btn-warning"
              >
                Панель администратора
              </button>
              <button class="btn btn-success btn__out">Пополнить счет</button>
              <button @click="func_sign_out" class="btn btn-danger btn__out">
                Выйти
              </button>
            </div>
          </div>
        </div>
        <div class="row account__wrapper">
          <div class="col-lg-8">
            <div class="user__description" v-if="user">
              <h4>
                {{
                  user.first_name
                    ? user.last_name + " " + user.first_name
                    : user.first_name
                }}
                <button
                  class="btn btn-success"
                  title="Настройки пользователя"
                  @click="setting_show = !setting_show"
                >
                  Настройки пользователя
                </button>
              </h4>
              <ul>
                <li>
                  Email:
                  <span :class="{ isEmail: !user.email }">{{
                    user.email ? user.email : "Укажите действующий email адрес"
                  }}</span>
                </li>
                <li>
                  Дата и время авторизации:
                  <span>{{
                    new Date(user.date_time_auth).toLocaleDateString() +
                    " " +
                    new Date(user.date_time_auth).toLocaleTimeString()
                  }}</span>
                </li>
                <li>
                  Количество заказов:
                  <span>{{ user.count_order ? user.count_order : 0 }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="user__pay" v-if="user">
              <ul>
                <li>
                  Баланс:
                  <span
                    >{{
                      user.balance
                        ? Number(user.balance).toFixed(2)
                        : Number(0).toFixed(2)
                    }}
                    руб.
                  </span>
                </li>
                <li>
                  Кол-во баллов: <span>{{ user.bal ? user.bal : 0 }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <HistoryOrders />
        <ProductsOrder />
        <HistoryProduct />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";
import HistoryProduct from "@/components/v-history-view.vue";
import SettingUser from "@/components/v-setting-user.vue";
import HistoryOrders from "@/components/v-history-orders.vue";
import ProductsOrder from "@/components/v-products-order.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    CatalogRow,
    Footer,
    HistoryProduct,
    SettingUser,
    HistoryOrders,
    ProductsOrder,
  },
  data() {
    return {
      user: null,
      setting_show: false,
    };
  },
  methods: {
    func_get_user() {
      if (this.USER_STATE) {
        let user_id = this.USER_STATE.user_id;
        if (!user_id) {
          if (localStorage.getItem("user")) {
            let user = JSON.parse(localStorage.getItem("user"));
            user_id = user.user_id;
          }
        }
        if (user_id) {
          window
            .sendRequest({
              func: "store_get_user",
              auth: true,
              params: {
                user_id,
              },
            })
            .then((result) => {
              if (result) {
                this.user = result;
              }
            });
        }
      } else {
        this.$router.push("/");
      }
    },
    func_sign_out() {
      window
        .sendRequest({
          func: "store_auth_user",
          auth: false,
          params: {
            user_id: this.USER_STATE.user_id,
            auth: false,
          },
        })
        .then(() => {
          localStorage.removeItem("user");
          this.$store.commit("CHANGE_USER", {});
          this.$router.push("/");
        });
    },
    func_show_admin() {
      if (this.user && Number(this.user.user_type_id) === 5) this.$router.push("/admin");
    },
    func_back_setting(user) {
      this.setting_show = !this.setting_show;
      if (user) {
        this.user.last_name = user.last_name;
        this.user.first_name = user.first_name;
        this.user.email = user.email;
      }
    },
  },
  mounted() {
    this.func_get_user();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style lang="scss" scoped>
.account {
  .title__account {
    padding: 5px;
    border-bottom: 3px solid var(--green);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .buttons {
      display: flex;
      .btn__out {
        margin-left: 10px;
        margin-right: 0;
        display: block;
        border: 1px solid #e2e2e2;
      }
    }
  }
  .account__wrapper {
    min-height: 150px;
    margin-top: 20px;
  }
}
.isEmail {
  color: red;
}
.user__description {
  .btn__setting {
    border: 0;
    background: transparent;
    transition: 0.5s;
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 0.8;
    }
    svg {
      color: var(--green);
    }
  }
  ul {
    list-style-type: none;
    font-size: 14px;
    margin-top: 25px;
    li {
      margin-top: 5px;
      span {
        font-weight: 700;
      }
    }
  }
}
.user__pay {
  margin-top: 20px;
  ul {
    list-style-type: none;
    text-align: right;
    li {
      font-size: 18px;
      font-weight: 700;
      span {
        color: var(--green);
      }
    }
  }
}
</style>
