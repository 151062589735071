<template>
  <div class="edit__catalog__wrapper">
    <div class="edit__catalog">
      <div class="menu">
        <span>Выберите категорию</span>
        <ul class="mainmenu" @click="new_category = false">
          <DropItem
            v-show="item.disable !== true"
            v-for="(item, index) in menu_list"
            :key="index"
            :item="item"
          />
        </ul>
        <button @click="func_add_category" class="btn btn-info btn__add">
          Добавить категорию
        </button>
      </div>
      <div class="edit">
        <div class="new__category" v-if="new_category">
          <h5>Добавить главную категорию</h5>
          <form class="form" @submit.prevent="func_add_new_category">
            <div class="form-group">
              <label for="new_category_title">Название</label>
              <input
                class="form-control"
                type="text"
                name="new_category_title"
                id="new_category_title"
                required
                v-model="new_category_data.title"
              />
            </div>
            <div class="form-group">
              <label for="new_category_description">Описание</label>
              <textarea
                class="form-control"
                name="new_category_description"
                id="new_category_description"
                v-model="new_category_data.description"
                required
              />
            </div>
            <div class="input-group">
              <div>
                <input
                  type="file"
                  ref="file_new"
                  v-on:change="func_file_new_upload()"
                  required
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                />
              </div>
            </div>
            <button class="btn btn-success btn__add">Добавить</button>
          </form>
        </div>
        <div class="edit__category" v-if="selected_menu">
          <h5>Категория "{{ selected_menu.title }}"</h5>
          <div
            class="pod"
            v-show="
              menu_list.filter((x) => x.menu_parent_id === selected_menu.menu_id).length >
              0
            "
          >
            <h6>Подкатегории</h6>
            <ul class="pod_catalog">
              <li
                v-for="item in menu_list.filter(
                  (x) => x.menu_parent_id === selected_menu.menu_id
                )"
                :key="item.menu_id"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>

          <form class="form" @submit.prevent="func_edit_category">
            <div class="form-group">
              <label for="new_pod_category_title">Название</label>
              <input
                class="form-control"
                type="text"
                name="new_pod_category_title"
                id="new_pod_category_title"
                required
                v-model="selected_menu.title"
              />
            </div>
            <div class="form-group">
              <label for="new_pod_category_description">Описание</label>
              <textarea
                class="form-control"
                name="new_pod_category_description"
                id="new_pod_category_description"
                v-model="selected_menu.description"
                required
              />
            </div>
            <div class="input-group">
              <div>
                <input
                  type="file"
                  ref="file_edit"
                  v-on:change="func_file_edit_upload()"
                  required
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                />
              </div>
            </div>
            <button class="btn btn-success btn__add">Редактировать</button>
            <button class="btn btn-warning btn__add" @click="func_delete_menu">
              Удалить
            </button>
          </form>
          <div class="line"></div>
          <h5>Добавить подкатегорию</h5>
          <form class="form" @submit.prevent="func_add_pod_category">
            <div class="form-group">
              <label for="add_pod_category_title">Название</label>
              <input
                class="form-control"
                type="text"
                name="add_pod_category_title"
                id="add_pod_category_title"
                required
                v-model="add_pod_category_data.title"
              />
            </div>
            <div class="form-group">
              <label for="add_pod_category_description">Описание</label>
              <textarea
                class="form-control"
                name="add_pod_category_description"
                id="add_pod_category_description"
                v-model="add_pod_category_data.description"
                required
              />
            </div>
            <div class="input-group">
              <div>
                <input
                  type="file"
                  ref="file_new_dop"
                  v-on:change="func_file_new_dop_upload()"
                  required
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                />
              </div>
            </div>
            <button class="btn btn-success btn__add">Добавить</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropItem from "@/components/v-catalog-drop-item.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    DropItem,
  },
  data() {
    return {
      menu_list: [],
      selected_menu: null,
      new_category: false,
      file: null,
      new_category_data: {
        title: "",
        description: "",
        img: "",
      },
      add_pod_category_data: {
        title: "",
        description: "",
        img: "",
      },
    };
  },
  watch: {
    CATALOG_SELECT_STATE() {
      this.selected_menu = this.CATALOG_SELECT_STATE;
    },
  },
  methods: {
    func_file_new_upload() {
      this.file = this.$refs.file_new.files[0];
    },
    func_file_edit_upload() {
      this.file = this.$refs.file_edit.files[0];
    },
    func_file_new_dop_upload() {
      this.file = this.$refs.file_new_dop.files[0];
    },
    func_get_menu() {
      window
        .sendRequest({
          func: "store_get_category",
          auth: false,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            const tree = (array) =>
              array
                .reduce((a, c) => {
                  c.submenu = array.filter((i) => i.menu_parent_id == c.menu_id);
                  a.push(c);
                  return a;
                }, [])
                .filter((i) => i.menu_parent_id === 0);
            this.menu_list = tree(result);
          }
        });
    },
    func_select_item(item) {
      if (item) {
        if (this.selected_menu && this.selected_menu.menu_id === item.menu_id) {
          this.selected_menu = null;
        } else {
          this.selected_menu = item;
        }
        this.new_category = false;
      }
    },
    func_add_category() {
      this.selected_menu = null;
      this.new_category = true;
    },
    func_add_new_category() {
      if (this.new_category_data.title && this.new_category_data.description) {
        window
          .sendRequest({
            func: "store_add_new_category",
            auth: false,
            params: {
              title: this.new_category_data.title,
              description: this.new_category_data.description,
              path: "menu",
              img: this.file,
            },
          })
          .then((result) => {
            if (result && result.menu_id) {
              this.new_category_data.title = "";
              this.new_category_data.description = "";
              this.$refs.file_new = null;
              this.file = null;
              this.func_get_menu();
            }
          });
      }
    },
    func_edit_category() {
      if (this.selected_menu.title && this.selected_menu.description) {
        window
          .sendRequest({
            func: "store_edit_category",
            auth: false,
            params: {
              title: this.selected_menu.title,
              description: this.selected_menu.description,
              menu_id: this.selected_menu.menu_id,
              img: this.file || null,
              path: "menu",
              delete: false,
            },
          })
          .then((result) => {
            if (result) {
              this.selected_menu = null;
              this.$refs.file_edit = null;
              this.file = null;
              this.func_get_menu();
            }
          });
      }
    },
    func_add_pod_category() {
      if (this.add_pod_category_data.title && this.add_pod_category_data.description) {
        window
          .sendRequest({
            func: "store_add_dop_category",
            auth: false,
            params: {
              title: this.add_pod_category_data.title,
              description: this.add_pod_category_data.description,
              path: "menu",
              menu_parent_id: this.selected_menu.menu_id,
              img: this.file || null,
            },
          })
          .then((result) => {
            if (result && result.menu_id) {
              this.func_get_menu();
              this.$refs.file_add_dop = null;
              this.file = null;
              this.add_pod_category_data.title = "";
              this.add_pod_category_data.description = "";
            }
          });
      }
    },
    func_delete_menu() {
      if (this.selected_menu) {
        window
          .sendRequest({
            func: "store_edit_category",
            auth: false,
            params: {
              menu_id: this.selected_menu.menu_id,
              delete: true,
            },
          })
          .then((result) => {
            if (result) {
              this.func_get_menu();
            }
          });
      }
    },
  },
  mounted() {
    this.func_get_menu();
  },
  computed: {
    ...mapGetters(["CATALOG_SELECT_STATE"]),
  },
};
</script>

<style lang="scss">
.edit__catalog__wrapper {
  min-height: 60vh;
}
.edit__catalog {
  display: flex;
  .menu {
    flex-basis: 30%;
  }
  .edit {
    flex-basis: 70%;
    padding: 10px;
    .new__category {
    }
  }
}
.selected {
  background: var(--green) !important;
  color: #fff;
}
.btn__add {
  margin-top: 15px;
  margin-right: 10px;
}
.line {
  margin-top: 25px;
  margin-bottom: 25px;
  border-bottom: 2px solid var(--green);
}
.pod_catalog {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  li {
    margin-right: 5px;
    padding: 5px;
    background: var(--green);
    opacity: 0.8;
    color: #fff;
  }
}
</style>
