<template>
  <div class="select__wrapper">
    <h4>Товары недели</h4>
    <div class="product__week__wrapper">
      <div class="product__week__item" v-for="week in weeks" :key="week.week_number">
        <h5>Неделя № {{ week.week_number }}</h5>
        <ul v-if="week.products && week.products.length > 0">
          <li v-for="product in week.products" :key="product.product_id">
            <div class="filter__img__product">
              <img
                :src="product.img ? '/img/products/' + product.img : '/img/fallback.png'"
                alt=""
              />
            </div>
            <div class="product__title">
              <span><i>Наименование: </i>{{ product.product_name }}</span>
              <span><i>Цена: </i>{{ product.new_price || 0 }} руб.</span>
            </div>
            <div class="product__button">
              <button
                @click="func_remove_product(week.week_number, product)"
                class="btn btn-danger"
              >
                Удалить
              </button>
            </div>
          </li>
        </ul>
        <ProductsSelect @add="func_add_product" :week_number="week.week_number" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsSelect from "@/components/v-product-select.vue";
export default {
  components: {
    ProductsSelect,
  },
  data() {
    return {
      weeks: [],
    };
  },
  methods: {
    func_get_weeks() {
      window
        .sendRequest({
          func: "store_get_product_week",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.weeks = result;
          }
        });
    },
    func_add_product(obj) {
      if (!obj) return;
      this.func_db_product(obj.week_number, obj.product, false);
    },
    func_remove_product(week_number, product) {
      this.func_db_product(week_number, product, true);
    },
    func_db_product(week_number, product, disable) {
      window
        .sendRequest({
          func: "store_set_product_action",
          auth: true,
          params: {
            week_number,
            product_id: product.product_id,
            disable,
          },
        })
        .then((result) => {
          if (!disable) {
            if (result && result.status) {
              let item = this.weeks.find(
                (x) => Number(x.week_number) === Number(week_number)
              );
              if (item) {
                if (!item.products) item.products = [];
                item.products.push(product);
              }
              this.$notify({
                group: "foo",
                title: "Информация",
                text: "Выполнено успешно",
                type: "info",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Предупреждение",
                text: result.msg,
                type: "warn",
              });
            }
          } else {
            if (result && result.status) {
              if (!week_number || !product) return;
              let products = this.weeks.find(
                (x) => Number(x.week_number) === Number(week_number)
              ).products;
              if (products) {
                products.splice(products.indexOf(product), 1);
                this.$notify({
                  group: "foo",
                  title: "Информация",
                  text: "Выполнено успешно",
                  type: "info",
                });
              }
            }
          }
        });
    },
  },
  mounted() {
    this.func_get_weeks();
  },
};
</script>

<style lang="scss">
.select__wrapper {
  .select__input {
    width: 100%;
    height: 100%;
    border: 1px solid #e2e2e2;
    position: relative;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .product__week__item {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 10px 0;
      li {
        transition: 0.5s;
        border-bottom: 1px solid #c2c2c2;
        padding: 5px;
        display: flex;
        align-items: center;
        span {
          width: 100%;
          display: block;
        }
        &:last-child {
          border-bottom: none;
        }
        .filter__img__product {
          width: 50px;
          margin-right: 5px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
            display: flex;
            object-fit: cover;
            object-position: center;
          }
        }
        .product__title {
          flex-basis: 80%;
        }
      }
    }
  }
}
</style>
