<template>
  <div class="actions__wrapper" v-if="actions_list.length > 0">
    <div class="actions__title">
      <h3>История просмотров</h3>
    </div>
    <div class="actions__content">
      <div class="but__prev" v-show="count_minus > 0" @click="func_slide(false)">
        <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
      </div>
      <div
        class="but__next"
        @click="func_slide(true)"
        v-show="this.count_show < this.actions_list.length"
      >
        <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
      </div>
      <div
        class="actions__item"
        v-for="action in actions_list.slice(
          count_minus,
          count_show === 0 ? count_visible : count_show
        )"
        :key="action.product_action_id"
      >
        <div class="actions__description">
          <ProductItem :product="action" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/v-product-item.vue";
export default {
  components: {
    ProductItem,
  },
  data() {
    return {
      actions_list: [],
      count_show: 0,
      count_plus: 1,
      count_visible: 4,
      count_minus: 0,
    };
  },
  methods: {
    func_slide(next) {
      if (next) {
        if (this.count_show === 0) {
          this.count_show = this.count_visible + this.count_plus;
        } else {
          this.count_show += this.count_plus;
        }
        this.count_minus += this.count_plus;
      } else {
        this.count_show -= this.count_plus;
        this.count_minus -= this.count_plus;
      }
    },
    func_get_data() {
      window
        .sendRequest({
          func: "store_get_product_history",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.actions_list = result.sort((a, b) => {
              if (new Date(a.date_view) > new Date(b.date_view)) {
                return -1;
              } else {
                return 1;
              }
            });
            if (result.length >= 4) {
              this.count_show = 4;
            } else {
              this.count_show = result.length;
            }
          }
        });
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style lang="scss" scoped>
.actions__title {
  text-align: center;
  background: #fff;
  border-bottom: 3px solid var(--green);
  h3 {
    color: var(--green);
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 0;
    font-size: 18px;
  }
}
.actions__content {
  margin: 15px 0;
  width: 100%;
  position: relative;
  display: flex;
  margin: 0 auto;
  align-items: center;
  .but__prev {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    z-index: 9998;
  }
  .but__next {
    position: absolute;
    top: 0;
    font-size: 18px;
    right: 10px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9998;
  }
  .actions__item {
    flex-basis: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 0.8;
    }
    .actions__description {
      width: 100%;
      padding: 10px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transform: scale(1.2);
  transition: 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
