<template>
  <div>
    <Header />
    <CatalogRow :catalog="true" />
    <div v-if="news" class="news">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="new__title">
              <div class="back">
                <button class="btn btn-warning" @click="$router.push('/')">
                  <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                </button>
              </div>
              <div class="title">{{ news.title }}</div>
              <div class="date_time">
                {{ new Date(news.date_time).toLocaleDateString() }}
              </div>
            </div>
            <div class="new__content" v-html="news.text"></div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";
export default {
  components: {
    Header,
    CatalogRow,
    Footer,
  },
  props: {
    new_id: Number,
  },
  data() {
    return {
      news: null,
    };
  },
  methods: {
    func_get_news(new_id) {
      window
        .sendRequest({
          func: "store_get_new",
          auth: false,
          params: {
            new_id: new_id,
          },
        })
        .then((result) => {
          if (result && result.new_id) {
            this.news = result;
          }
        });
    },
  },
  mounted() {
    if (this.new_id) this.func_get_news(this.new_id);
  },
};
</script>

<style lang="scss" scoped>
.new__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 3px solid var(--green);
  .title {
    font-size: 22px;
    font-weight: 700;
  }
}
.new__content {
  padding: 10px;
}
</style>