<template>
  <div>
    <Header />
    <CatalogRow :catalog="true" />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="search" class="search__wrapper">
            <h4>Результаты запроса '{{ search }}'</h4>
            <div
              class="search__none"
              v-if="
                (!products || products.length === 0) &&
                (!catalog_group || catalog_group.length === 0)
              "
            >
              <div>
                <img src="@/assets/img/no_serch.jpg" alt="" />
                <p>Товары не найдены</p>
              </div>
            </div>
            <div class="catalog__group" v-if="catalog_group && catalog_group.length > 0">
              <h5 class="title">Категории:</h5>
              <ul>
                <li
                  v-for="menu in catalog_group"
                  :key="menu.menu_id"
                  @click="func_show_menu(menu)"
                >
                  <div class="menu__item">
                    <div class="img__menu">
                      <img
                        :src="menu.img ? '/img/menu/' + menu.img : '/img/fallback.png'"
                        alt=""
                      />
                    </div>
                    <div class="menu__title">
                      <h5>{{ menu.title }}</h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="products" v-if="products && products.length > 0">
              <h5 class="title">Товары:</h5>
              <div class="row">
                <div
                  class="col-lg-3"
                  v-for="product in products"
                  :key="product.product_id"
                >
                  <ProductItem :product="product" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";
import ProductItem from "@/components/v-product-item.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    CatalogRow,
    Footer,
    ProductItem,
  },
  data() {
    return {
      catalog_group: [],
      products: [],
      search: "",
    };
  },
  watch: {
    SEARCH_STATE() {
      this.search = this.SEARCH_STATE;
      if (this.search) this.func_search(this.search);
    },
  },
  methods: {
    func_search(search) {
      window
        .sendRequest({
          func: "store_search",
          auth: false,
          params: {
            search,
          },
        })
        .then((result) => {
          if (result) {
            this.catalog_group = result.catalog;
            this.products = result.products;
          }
        });
    },
    func_show_menu(menu) {
      this.$store.commit("CHANGE_CATALOG_SELECT", menu);
      if (this.$router.history.current.name !== "catalog") {
        this.$router.push("/catalog");
      }
      this.$route.params.menu = menu;
    },
  },
  computed: {
    ...mapGetters(["SEARCH_STATE"]),
  },
  mounted() {
    if (this.SEARCH_STATE) {
      this.search = this.SEARCH_STATE;
      if (this.search) this.func_search(this.search);
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-bottom: 5px;
  color: var(--green);
  text-transform: uppercase;
  border-bottom: 3px solid var(--green);
  font-weight: 700;
}
.search__wrapper {
  min-height: 50vh;
  & > h4 {
    text-align: center;
  }
  .search__none {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .catalog__group {
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      li {
        flex-basis: 200px;
        cursor: pointer;
        margin: 5px;
        height: 200px;
        border: 1px solid #e2e2e2;
        transition: 0.5s;
        &:hover {
          box-shadow: 0 0 5px var(--red);
        }
        .menu__item {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img__menu {
            height: 80%;
            img {
              width: 100%;
              height: 100%;
              display: flex;
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .menu__title {
          flex-basis: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          h5 {
            padding: 5px;
            font-size: 17px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
