<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Авторизация</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="auth_form form" @submit.prevent="func_auth">
            <p>
              Если вы зарегистрированы на сайте
              <a href="https://bpcard.ru/">bpcard.ru</a>, можете войти с помощью своих
              учетных данных
            </p>
            <div class="form-group">
              <label for="login">Ваш логин</label>
              <input
                type="text"
                id="login"
                required
                class="form-control"
                v-model="login"
              />
            </div>
            <div class="form-group">
              <label for="password">Ваш пароль</label>
              <input
                type="password"
                id="password"
                required
                class="form-control"
                v-model="password"
              />
            </div>
            <p @click="func_register" class="regiter">Еще не зарегистрированы?</p>
            <button class="btn">Войти</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      login: "",
      password: "",
      token: null,
    };
  },
  methods: {
    func_auth() {
      if (this.login && this.password) {
        window
          .sendRequest({
            func: "store_auth_user",
            auth: false,
            params: {
              login: this.login,
              password: this.password,
              token: this.func_generate_token(32),
              auth: true,
            },
          })
          .then((result) => {
            if (result && !result.msg) {
              localStorage.setItem("user", JSON.stringify(result));
              this.$store.commit("CHANGE_USER", result);
              this.$store.commit("CHANGE_MODAL", { show: false, module: null });
              this.$notify({
                group: "foo",
                title: "Информация",
                text: "Вы успешно авторизовались",
                type: "info",
              });
            }
            if (result.msg) {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: result.msg,
                type: "warn",
              });
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Заполните все поля",
          type: "warn",
        });
      }
    },
    func_register() {
      this.$store.commit("CHANGE_MODAL", { show: false, module: null });
      setTimeout(() => {
        this.$store.commit("CHANGE_MODAL", { show: true, module: "register" });
      }, 200);
    },
    func_generate_token(length) {
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      var b = [];
      for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
      }
      return b.join("");
    },
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style lang="scss">
.auth_form {
  p {
    text-align: center;
    font-size: 13px;
    &.regiter {
      cursor: pointer;
      font-size: 15px;
      color: var(--red);
    }
  }
  button {
    margin: 0 auto;
    display: block;
    background: var(--green);
    color: #fff;
    padding: 10px 30px;
    transition: 0.5s;
    &:hover {
      opacity: 0.8;
      color: #fff;
    }
  }
}
</style>
