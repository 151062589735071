import axios from 'axios'
import store from './store.js';


window.sendRequest = function(json) {
    store.commit("CHANGE_PROGRESS", true);
    let fd = new FormData();
    fd.append('data', JSON.stringify(json));
    for (const param in json.params) {
        if (typeof json.params[param] === 'object') {
            fd.append('file', json.params[param]);
        }
    }
    return new Promise(function(resolve, reject) {
        axios({
                method: "post",
                url: "https://store.bpcard.ru/backend/index.php",
                headers: {
                    auth: json.auth,
                    token: json.auth ? (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null) : null,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: fd,
            })
            .then((response) => {
                store.commit("CHANGE_PROGRESS", false);
                if (response) {
                    if (response.data.code === 1) {
                        console.error(response.data, json);
                        resolve(response.data || null);
                    } else {
                        resolve(response.data || null);
                    }
                }
            })
            .catch(function(error) {
                store.commit("CHANGE_PROGRESS", false);
                console.error(error);
                reject({ status: 1, errorText: error });
            });
    });
}