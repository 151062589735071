<template>
  <div class="setting__user__wrapper">
    <div class="container">
      <div class="row">
        <div class="setting__title">
          <div class="back">
            <button
              class="btn btn-warning"
              @click="save ? $emit('back', user) : $emit('back')"
            >
              <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
            </button>
          </div>
          <h4>Настройки пользователя</h4>
        </div>
        <div class="setting__user">
          <form @submit.prevent="func_save_user_setting" class="setting__user__form">
            <div class="form-group">
              <label>Логин</label>
              <input type="text" class="form-control" disabled v-model="user.login" />
            </div>
            <div class="form-group">
              <label>Фамилия</label>
              <input type="text" class="form-control" required v-model="user.last_name" />
            </div>
            <div class="form-group">
              <label>Имя</label>
              <input
                type="text"
                class="form-control"
                required
                v-model="user.first_name"
              />
            </div>
            <div class="form-group">
              <label>e-mail</label>
              <input type="email" class="form-control" required v-model="user.email" />
            </div>
            <button :disabled="save" class="btn btn-info">Сохранить</button>
          </form>
          <div class="setting__info">
            <h5>Общая инофрмация</h5>
            <ul>
              <li>
                Текущий баланс: <span>{{ info.balance }}</span>
              </li>
              <li>
                Количество баллов: <span>{{ info.bal }}</span>
              </li>
              <li>
                Количество заказов: <span>{{ info.orders }}</span>
              </li>
              <li>
                Количество просмотров товара: <span>{{ info.history }}</span>
              </li>
              <li>
                Товаров в корзине: <span>{{ info.cart }}</span>
              </li>
              <li>
                Товаров в избранном: <span>{{ info.favor }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      save: false,
      info: {
        balance: 0,
        bal: 0,
        history: 0,
        orders: 0,
        cart: 0,
        favor: 0,
      },
    };
  },
  methods: {
    func_get_user_setting() {
      window
        .sendRequest({
          func: "store_get_user_setting",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.user = result.setting;
            if (result.info) {
              this.info.balance = result.info.balance || 0;
              this.info.bal = result.info.bal || 0;
              this.info.history = result.info.count_history || 0;
              this.info.orders = result.info.orders;
              this.info.cart = localStorage.getItem("cart")
                ? JSON.parse(localStorage.getItem("cart")).length
                : 0;
              this.info.favor = localStorage.getItem("favor")
                ? JSON.parse(localStorage.getItem("favor")).length
                : 0;
            }
          }
        });
    },
    func_save_user_setting() {
      window
        .sendRequest({
          func: "store_set_user_setting",
          auth: true,
          params: this.user,
        })
        .then((result) => {
          if (result) {
            if (result.status) {
              this.$notify({
                group: "foo",
                title: "Информация",
                text: "Изменения были сохранены",
                type: "info",
              });
              this.save = true;
            } else {
              this.$notify({
                group: "foo",
                title: "Предупреждение",
                text: result.msg,
                type: "warn",
              });
            }
          }
        });
    },
  },
  mounted() {
    this.func_get_user_setting();
  },
};
</script>

<style lang="scss">
.setting__user__wrapper {
  .setting__title {
    display: flex;
    align-items: center;
    border-bottom: 3px solid var(--green);
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 5px;
    .back {
      margin-right: 10px;
    }
  }
  .setting__user {
    width: 100%;
    display: flex;
    .setting__user__form,
    .setting__info {
      flex-basis: 50%;
      padding: 10px;
    }
    .setting__info {
      text-align: right;
      ul {
        list-style-type: none;
        li {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
