<template>
  <div>
    <Header />
    <CatalogRow :catalog="true" />
    <div class="admin__wrapper">
      <div class="container">
        <h3>Панель администратора</h3>
        <div class="row">
          <div class="col-lg-4">
            <ul class="menu__admin">
              <li @click="func_show_component('edit_menu')">Редактировать меню</li>
              <li @click="func_show_component('add_product')">Добавить товар</li>
              <li @click="func_show_component('product_list')">Список товаров</li>
              <li @click="func_show_component('product_week')">Товары недели</li>
              <li @click="func_show_component('news_list')">Список новостей</li>
              <li @click="func_show_component('products_order')">Товары под заказ</li>
              <li @click="func_show_component('promo_list')">Список промокодов</li>
            </ul>
          </div>
          <div class="col-lg-8">
            <EditCatalog v-if="components.find((x) => x.name === 'edit_menu').show" />
            <AddProduct v-if="components.find((x) => x.name === 'add_product').show" />
            <ProductList
              @edit_product="func_edit_product"
              v-if="components.find((x) => x.name === 'product_list').show"
            />
            <EditProduct
              :product="edit_product"
              v-if="components.find((x) => x.name === 'edit_product').show"
              @show_list="func_show_component('product_list')"
            />
            <ProductWeek
              v-if="components.find((x) => x.name === 'product_week').show"
              @show_list="func_show_component('product_week')"
            />
            <NewsList
              v-if="components.find((x) => x.name === 'news_list').show"
              @show_list="func_show_component('news_list')"
            />
            <ProductsOrder
              v-if="components.find((x) => x.name === 'products_order').show"
              @show_list="func_show_component('products_order')"
            />
            <Promo
              v-if="components.find((x) => x.name === 'promo_list').show"
              @show_list="func_show_component('promo_list')"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";

import EditCatalog from "@/components/admins/v-edit-catalog.vue";
import AddProduct from "@/components/admins/v-add-product.vue";
import ProductList from "@/components/admins/v-products-list.vue";
import EditProduct from "@/components/admins/v-edit-product.vue";
import ProductWeek from "@/components/admins/v-product-week.vue";
import NewsList from "@/components/admins/v-news-list.vue";
import ProductsOrder from "@/components/admins/v-products-order.vue";
import Promo from "@/components/admins/v-promo-list.vue";

export default {
  components: {
    Header,
    CatalogRow,
    Footer,
    EditCatalog,
    AddProduct,
    ProductList,
    EditProduct,
    ProductWeek,
    NewsList,
    ProductsOrder,
    Promo,
  },
  data() {
    return {
      components: [
        { name: "edit_menu", show: false },
        { name: "add_product", show: false },
        { name: "product_list", show: false },
        { name: "edit_product", show: false },
        { name: "product_week", show: false },
        { name: "news_list", show: false },
        { name: "products_order", show: false },
        { name: "promo_list", show: false },
      ],
      edit_product: null,
    };
  },

  methods: {
    func_show_component(component) {
      if (component) {
        this.components.forEach((item) => {
          if (item.name === component) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
      }
    },
    func_edit_product(product) {
      if (product) {
        this.edit_product = product;
        this.func_show_component("edit_product");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.menu__admin {
  list-style-type: none;
  li {
    padding: 10px;
    background: #f2f2f2;
    border-bottom: 1px solid #c2c2c2;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: var(--green);
      color: #fff;
    }
  }
}
</style>
