<template>
  <div class="add__product__wrapper">
    <h5>Добавить продукт</h5>
    <div class="add__product">
      <div class="menu">
        <span>Выберите категорию</span>
        <ul class="mainmenu" @click="new_category = false">
          <DropItem v-for="(item, index) in menu_list" :key="index" :item="item" />
        </ul>
      </div>
      <div class="form" v-if="selected_menu">
        <h6>Категория: {{ selected_menu.title }}</h6>
        <form @submit.prevent="func_save_product">
          <div class="form-group">
            <label>Название</label>
            <input
              type="text"
              required
              class="form-control"
              v-model="product.product_name"
            />
          </div>
          <div class="form-group">
            <label>Артикул</label>
            <input type="text" required class="form-control" v-model="product.article" />
          </div>
          <div class="form-group">
            <label>Производитель</label>
            <input type="text" required class="form-control" v-model="product.fabr" />
          </div>
          <div class="form-group">
            <label>Описание</label>
            <textarea
              class="form-control"
              required
              v-model="product.description"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Кол-во</label>
            <input
              type="number"
              class="form-control"
              required
              v-model="product.product_count"
            />
          </div>
          <div class="form-group">
            <label>Цена</label>
            <input
              type="number"
              class="form-control"
              required
              v-model="product.new_price"
            />
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="product.to_order"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault"> В наличии </label>
          </div>
          <div class="input-group">
            <div>
              <input
                type="file"
                ref="file"
                v-on:change="func_file_upload()"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              />
            </div>
          </div>
          <button class="btn btn-success">Добавить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DropItem from "@/components/v-catalog-drop-item.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    DropItem,
  },
  data() {
    return {
      menu_list: [],
      selected_menu: null,
      product: {
        product_name: "",
        article: "",
        new_price: 0,
        description: "",
        to_order: false,
        fabr: "",
        product_count: 0,
      },
      file: null,
    };
  },
  methods: {
    func_file_upload() {
      if (this.$refs.file) {
        this.file = this.$refs.file.files[0];
      }
    },
    func_get_menu() {
      window
        .sendRequest({
          func: "store_get_category",
          auth: false,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            const tree = (array) =>
              array
                .reduce((a, c) => {
                  c.submenu = array.filter((i) => i.menu_parent_id == c.menu_id);
                  a.push(c);
                  return a;
                }, [])
                .filter((i) => i.menu_parent_id === 0);
            this.menu_list = tree(result);
          }
        });
    },
    func_save_product() {
      if (this.product.product_name) {
        let json = {
          product_name: this.product.product_name,
          article: this.product.article,
          description: this.product.description,
          fabr: this.product.fabr,
          new_price: this.product.new_price,
          to_order: this.product.to_order,
          product_count: this.product.product_count,
          img: this.file || null,
          path: "products",
          menu_id: this.selected_menu.menu_id,
        };
        window
          .sendRequest({
            func: "store_add_product",
            auth: false,
            params: json,
          })
          .then((result) => {
            if (result) {
              this.product.product_name = "";
              this.product.article = "";
              this.product.description = "";
              this.product.fabr = "";
              this.product.new_price = "";
              this.product.to_order = "";
              this.product.product_count = "";
              this.file = null;
              this.$refs.file = null;
              this.$notify({
                group: "foo",
                title: "Инофрмация",
                text: "Товар добавлен",
                type: "info",
              });
            }
          });
      }
    },
  },
  watch: {
    CATALOG_SELECT_STATE() {
      this.selected_menu = this.CATALOG_SELECT_STATE;
    },
  },
  mounted() {
    this.func_get_menu();
  },
  computed: {
    ...mapGetters(["CATALOG_SELECT_STATE"]),
  },
};
</script>

<style lang="scss">
.add__product {
  display: flex;
  .menu {
    flex-basis: 30%;
  }
  .form {
    flex-basis: 70%;
    padding: 10px;
  }
}
.input-group {
  margin: 10px 0;
}
</style>
