<template>
  <div class="footer">
    <footer>
      <div class="container">
        <div class="row">
          <div class="footer__content">
            <div class="footer__item">
              <div class="footer__cart">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </div>
            </div>
            <div class="footer__item">
              <div class="footer__description">
                <ul class="footer__menu">
                  <li>О проекте</li>
                  <li>//</li>
                  <li>Инструкция</li>
                  <li>//</li>
                  <li>Контакты</li>
                  <li>//</li>
                  <li>Задать вопрос</li>
                </ul>
                <div class="footer__description__bottom">
                  <div class="footer__description__bootom__logo">
                    <img src="@/assets/img/fvc.png" alt="" />
                  </div>
                  <div class="footer__description__bootom__content">
                    <ul>
                      <li>г. Балаково ул. Московская д.78</li>
                      <li>
                        email:
                        <a href="mailto:info@bpcard.ru">info@bpcard.ru</a>
                      </li>
                      <li>
                        Тех. поддержка:
                        <a href="tel:892297778811">+7 (929) 777 88-11</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer__description__bootom__youtube">
                    <img src="@/assets/img/youtube.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="footer__item">
              <div class="footer__soc">
                <img src="@/assets/img/messanger/viber.png" alt="" />
                <img
                  src="@/assets/img/messanger/logo_whatsapp_ico.png"
                  alt=""
                />
                <img src="@/assets/img/messanger/telegram_PNG34.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>