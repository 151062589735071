<template>
  <div>
    <div class="product__img__wrapper">
      <div
        class="product__img__item"
        v-for="product in product_img_list"
        :key="product.product_images_id"
      >
        <ZoomImage
          :src="
            product.product_img
              ? require('../assets/img/products/' + product.product_img)
              : '../assets/img/no_photo.png'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import ZoomImage from "@/components/v-zoom-img.vue";
export default {
  props: {
    product_id: Number,
  },
  watch: {
    product_id: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      this.func_get_product_img(newVal);
    },
  },
  components: {
    ZoomImage,
  },
  data() {
    return {
      product_img_list: [],
    };
  },
  methods: {
    func_get_product_img(product_id) {
      this.product_img_list = [];
      if (!product_id) return;

      window
        .sendRequest({
          func: "store_get_product_images",
          auth: false,
          params: {
            product_id: product_id,
          },
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.product_img_list = result;
          }
        });
    },
  },
  mounted() {
    if (this.product_id) this.func_get_product_img(this.product_id);
  },
};
</script>

<style lang="scss" scoped>
.product__img__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .product__img__item {
    flex-basis: 22%;
    margin-right: 10px;
    margin-top: 10px;
    height: 70px;
    transition: 0.5s;
    box-shadow: 0 0 2px #a8a8a8;
    &:hover {
      box-shadow: 0 0 5px var(--green);
    }
    padding: 3px;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>