<template>
  <div class="progress" v-show="PROGRESS_STATE">
    <img src="../assets/img/2.gif" alt />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["PROGRESS_STATE"]),
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999999999;
}
</style>