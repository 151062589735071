<template>
  <div>
    <header>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="header__logo">
              <router-link to="/">
                <img
                  src="@/assets/img/logo_png_160px.png"
                  alt="Большая перемена"
                />
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="header__auth">
              <ul>
                <li>Комбинат питания</li>
                <li><a href="tel:892297778811">+7 (929) 777 88-11</a></li>
              </ul>
              <User />
            </div>
          </div>
        </div>
      </div>
      <div class="main__header__title">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="main__header">
                <span>Магазин лучших продуктов</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="main__header main__header__flex">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                <span class="header__title">Комбинат питания</span>
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="main__header">
                <span>Магазин лучших продуктов</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import User from "@/components/v-user.vue";

export default {
  components: {
    User,
  },
  data() {
    return {
      modal_show: false,
      modal_module: null,
    };
  },
  methods: {
    func_show_modal(modal_module) {
      this.modal_module = modal_module;
      this.modal_show = true;
    },
    func_close_modal() {
      this.modal_module = null;
      this.modal_show = false;
    },
  },
};
</script>

<style lang="scss">
.catalog_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn__catalog {
    margin: 10px 0;
    background: var(--red);
    color: #fff;
    transition: 0.5s;
    &:focus {
      outline: none !important;
    }
    &:hover {
      opacity: 0.8;
    }
    svg {
      margin-right: 5px;
    }
  }
  .catalog__menu__search {
    flex-basis: 70%;
    position: relative;
    input {
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 9px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .catalog__menu__buttons {
    display: flex;
    align-items: center;
    button {
      border: 1px solid #e8e8e8;
      position: relative;
      span {
        position: absolute;
        top: -30%;
        right: -10%;
        background: var(--red);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
      }
      &:first-child {
        margin-right: 8px;
        svg {
          margin-right: 0px;
        }
      }
      &:last-child {
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

header {
  .header__logo {
    margin: 10px 0;
    img {
      width: 40%;
    }
  }
  .header__auth {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    ul {
      margin-right: 15px;
      list-style-type: none;
      text-align: right;
      margin-bottom: 0;
      li {
        &:first-child {
          font-size: 20px;
          font-weight: 700;
          color: var(--green);
        }
        a {
          font-size: 15px;
          font-weight: 700;
          color: var(--red);
          text-decoration: none;
          transition: 0.5s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    button {
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 10px 25px;
    }
  }
  .main__header__title {
    background: var(--green);
    height: 50px;
    color: #fff;
    .container,
    .row {
      height: 100%;
    }
    .main__header {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: lowercase;
      height: 100%;
    }
    .main__header__flex {
      justify-content: space-between;
      .header__title {
        font-size: 20px;
        text-transform: uppercase;
        font-family: "Tahoma";
        font-weight: 700;
      }
      svg {
        font-size: 18px;
      }
    }
  }
}
</style>