import Vue from 'vue'
import Router from 'vue-router'

import Index from '../components/page/v-index';
import News from '../components/page/v-news';
import Catalog from '../components/page/v-catalog';
import Product from '../components/page/v-product';
import Account from '../components/page/v-account';
import Admin from '../components/page/v-admin';
import Search from '../components/page/v-search';
import Order from '../components/page/v-order';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        component: Index
    }, {
        path: '/news',
        name: 'news',
        component: News,
        props: true
    }, {
        path: '/catalog',
        name: 'catalog',
        component: Catalog,
        props: true
    }, {
        path: '/product/:id',
        name: 'product',
        component: Product,
    }, {
        path: '/account',
        name: 'account',
        component: Account
    }, {
        path: '/admin',
        name: 'admin',
        component: Admin
    }, {
        path: '/search',
        name: 'search',
        component: Search,
        props: true
    }, {
        path: '/order',
        name: 'order',
        component: Order
    }]
})

export default router;