<template>
  <div class="products__list__wrapper">
    <h5>Список товаров</h5>
    <div class="product__list__filter">
      <input
        type="text"
        v-model="search_value"
        placeholder="Найти"
        class="form-control"
      />
    </div>
    <div class="products__list__table">
      <table class="table">
        <thead>
          <tr>
            <th @click="func_sort('product_id')" class="sort" scope="col">ИД товара</th>
            <th @click="func_sort('product_name')" class="sort" scope="col">
              Наименование
            </th>
            <th @click="func_sort('article')" class="sort" scope="col">Артикул</th>
            <th @click="func_sort('fabr')" class="sort" scope="col">Производитель</th>
            <th @click="func_sort('new_price')" class="sort" scope="col">Цена</th>
            <th @click="func_sort('to_order')" class="sort" scope="col">В наличии</th>
            <th @click="func_sort('disable')" class="sort" scope="col">Удален</th>
            <th class="sort" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            @click="func_edit_product(product, $event)"
            v-for="product in filter_product_list"
            :key="product.product_id"
          >
            <th>{{ product.product_id }}</th>
            <th>{{ product.product_name }}</th>
            <th>{{ product.article }}</th>
            <th>{{ product.fabr }}</th>
            <th>{{ product.new_price }}</th>
            <th>{{ product.to_order ? "Нет" : "Да" }}</th>
            <th>{{ product.disable ? "Да" : "Нет" }}</th>
            <th>
              <button class="btn btn-danger" @click="func_remove_product(product)">
                {{ product.disable ? "Восстановить" : "Удалить" }}
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product_list: [],
      filter_product_list: [],
      search: "",
      sort: null,
      sort_type: 1,
    };
  },
  methods: {
    func_get_products() {
      window
        .sendRequest({
          func: "store_get_product_list",
          auth: false,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.product_list = result;
            this.filter_product_list = this.product_list;
          }
        });
    },
    func_sort(type) {
      this.filter_product_list.sort((a, b) => {
        if (this.sort === type) {
          if (this.sort_type === 1) {
            return a[type] > b[type] ? 1 : -1;
          } else {
            return a[type] < b[type] ? 1 : -1;
          }
        } else {
          if (this.sort_type === 1) {
            return a[type] > b[type] ? 1 : -1;
          } else {
            return a[type] < b[type] ? 1 : -1;
          }
        }
      });
      this.sort = type;
      this.sort_type = this.sort_type === 1 ? 2 : 1;
    },
    func_remove_product(product) {
      if (product) {
        let index = this.filter_product_list.indexOf(product);
        window
          .sendRequest({
            func: "store_remove_product",
            auth: false,
            params: {
              product_id: product.product_id,
              disable: !this.filter_product_list[index].disable,
            },
          })
          .then((result) => {
            if (result) {
              this.filter_product_list[index].disable = !this.filter_product_list[index]
                .disable;
            }
          });
      }
    },
    func_edit_product(product, e) {
      if (e.target.type === "submit") return;
      if (product) this.$emit("edit_product", product);
    },
  },
  mounted() {
    this.func_get_products();
  },
  computed: {
    search_value: {
      get() {
        return this.search;
      },
      set(value) {
        this.filter_product_list = this.product_list.filter((x) => {
          return (
            x.product_name.toString().includes(value) ||
            x.product_id.toString().includes(value) ||
            x.article.toString().includes(value) ||
            x.fabr.toString().includes(value)
          );
        });
        this.search = value;
      },
    },
  },
};
</script>

<style lang="scss">
.product__list__filter {
  padding: 15px 0;

  display: flex;
  button {
    margin-left: 15px;
  }
}
.sort {
  cursor: pointer;
  &:hover {
    border: 1px solid #e2e2e2;
  }
}
table {
  font-size: 12px;
}
table tr td.td2:last-child {
  word-break: break-word;
}
table tbody {
  tr {
    cursor: pointer;
    &:hover {
      background: #e2e2e2;
    }
  }
}
</style>
