<template>
  <div>
    <transition
      :name="catalog_menu_show ? 'slide_catalog_menu_show' : 'slide_catalog_menu_hide'"
    >
      <CatalogMenu
        v-if="catalog"
        v-show="catalog_menu_show"
        @close_catalog_menu="catalog_menu_show = false"
      />
    </transition>
    <transition
      :name="right_menu_show ? 'slide_right_menu_show' : 'slide_right_menu_hide'"
    >
      <RightMenu
        v-show="right_menu_show"
        @close_right_menu="right_menu_show = false"
        :cart="right_menu_cart"
      />
    </transition>
    <menu>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="catalog_menu">
              <button
                v-if="catalog"
                class="btn btn__catalog"
                @click="catalog_menu_show = !catalog_menu_show"
              >
                <i class="fa fa-bars" aria-hidden="true"></i>Каталог
              </button>

              <div class="catalog__menu__search">
                <input
                  type="text"
                  v-model="search"
                  required
                  class="form-control"
                  placeholder="Найти..."
                  @keyup.enter="func_search"
                />
                <button @click="func_search" class="btn__search">
                  <i class="fa fa-search" aria-hidden="true" title="Найти"></i>
                </button>
              </div>
              <div class="catalog__menu__buttons">
                <button
                  class="btn btn-light"
                  title="Избранное"
                  @click="func_show_right_menu(false)"
                >
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <span v-if="FAVORITES_STATE.length > 0">{{
                    FAVORITES_STATE.length
                  }}</span>
                </button>
                <button
                  class="btn btn-light"
                  title="Корзина"
                  @click="func_show_right_menu(true)"
                >
                  <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  <span v-if="CART_STATE.length > 0">{{ CART_STATE.length }}</span
                  >Корзина
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </menu>
  </div>
</template>

<script>
import RightMenu from "@/components/v-right-menu.vue";
import CatalogMenu from "@/components/v-catalog-menu.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    catalog: Boolean,
  },
  components: {
    RightMenu,
    CatalogMenu,
  },
  data() {
    return {
      catalog_menu_show: false,
      right_menu_show: false,
      right_menu_cart: null,
      search: "",
    };
  },
  methods: {
    func_show_right_menu(cart) {
      this.right_menu_cart = cart;
      this.right_menu_show = true;
    },
    func_search() {
      if (this.search !== "") {
        if (this.$router.history.current.name !== "search") this.$router.push("/search");
        this.$store.commit("CHANGE_SEARCH", this.search);
        this.search = "";
      } else {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Заполните поле поиска",
          type: "warn",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
    ...mapGetters(["FAVORITES_STATE"]),
    ...mapGetters(["SEARCH_STATE"]),
  },
};
</script>

<style lang="scss">
menu {
  background: #fff;
  margin: 10px 0;
  nav {
    width: 100%;
    margin: 10px 0;
    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-bottom: 0;
      flex-wrap: wrap;
      li {
        flex-basis: 33%;
        text-align: center;
        text-transform: uppercase;
        color: var(--red);
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        transition: 0.5s;
        will-change: transform;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.btn__search {
  display: contents;
}

.slide_catalog_menu_show-enter-active,
.slide_catalog_menu_show-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_catalog_menu_show-enter {
  transform: translateX(-100%);
}
.slide_catalog_menu_show-leave-to {
  transform: translateX(0px);
}

.slide_catalog_menu_hide-enter-active,
.slide_catalog_menu_hide-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_catalog_menu_hide-enter {
  transform: translateX(0px);
}
.slide_catalog_menu_hide-leave-to {
  transform: translateX(-100%);
}

.slide_right_menu_show-enter-active,
.slide_right_menu_show-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_right_menu_show-enter {
  transform: translateX(100%);
}
.slide_right_menu_show-leave-to {
  transform: translateX(0px);
}

.slide_right_menu_hide-enter-active,
.slide_right_menu_hide-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_right_menu_hide-enter {
  transform: translateX(0px);
}
.slide_right_menu_hide-leave-to {
  transform: translateX(100%);
}
</style>
