<template>
  <div class="product__item" v-if="product">
    <div class="percent" v-if="Number(product.old_price) > Number(product.new_price)">
      {{
        "-" +
        Math.round(
          (100 * (Number(product.old_price) - Number(product.new_price))) /
            Number(product.old_price)
        ) +
        "%"
      }}
    </div>
    <div class="product_img" @click="func_show_product(product.product_id)">
      <img
        :src="product.img ? '/img/products/' + product.img : '/img/products/fallback.png'"
        :alt="'Интернет магазин bpcard.ru ' + product.product_name"
      />
    </div>
    <div class="product_description">
      <ul>
        <li class="product__name">
          {{ product.product_name }}
        </li>
        <li>
          Категория: <span>{{ product.category }}</span>
        </li>
        <li>
          Артикул: <span>{{ product.article }}</span>
        </li>
        <li>
          Производитель: <span>{{ product.fabr }}</span>
        </li>
        <li>
          Тип: <span>{{ product.order ? "Под заказ" : "В наличии" }}</span>
        </li>
        <li>
          Цена:
          <div class="price">
            <span
              v-show="Number(product.old_price) > Number(product.new_price)"
              class="old__price"
              >{{ product.old_price }}р</span
            >
            <span class="new__price">{{ product.new_price }}р</span>
          </div>
        </li>
        <li v-if="product.date_view">
          Дата просмотра:
          <span>{{
            new Date(product.date_view).toLocaleDateString() +
            " " +
            new Date(product.date_view).toLocaleTimeString()
          }}</span>
        </li>
      </ul>
    </div>
    <div class="product__botton">
      <button
        class="btn btn-warning"
        @click="func_add_product(false, product)"
        title="В список"
      >
        <i class="fas fa-star" aria-hidden="true"></i>
      </button>
      <button
        class="btn btn-success"
        @click="func_add_product(true, product)"
        title="В корзину"
      >
        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    product: Object,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    func_add_product(cart_bool, product) {
      if (product) {
        if (cart_bool) {
          let cart = [...this.CART_STATE];
          if (cart.length > 0) {
            let obj = cart.find((item) => item.product_id === product.product_id);
            if (obj) {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар уже в корзине",
                type: "warn",
              });
              return;
            }
          }
          product.count = 1;
          cart.push(product);
          this.$store.commit("CHANGE_CART", cart);

          localStorage.setItem("cart", JSON.stringify(cart));

          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Товар добавлен в корзину",
            type: "info",
          });
        } else {
          let favor = [...this.FAVORITES_STATE];
          if (favor.length > 0) {
            let obj = favor.find((item) => item.product_id === product.product_id);
            if (obj) {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар уже в избранном",
                type: "warn",
              });
              return;
            }
          }
          favor.push(product);
          this.$store.commit("CHANGE_FAVORITES", favor);
          localStorage.setItem("favor", JSON.stringify(favor));

          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Товар добавлен в избранное",
            type: "info",
          });
        }
      }
    },
    func_show_product(product_id) {
      this.$router.push("/product/" + product_id);
    },
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
    ...mapGetters(["FAVORITES_STATE"]),
  },
};
</script>

<style lang="scss" scoped>
.product__item {
  position: relative;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
  .percent {
    position: absolute;
    top: 0;
    right: -80px;
    background: var(--green);
    padding: 5px 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transform: rotate(45deg);
  }
  .product_img {
    height: 250px;
    cursor: pointer;
    img {
      height: 100%;
      width: 100%;
      display: flex;
      object-fit: cover;
      object-position: center;
    }
  }
  .product_description {
    ul {
      list-style-type: none;
      margin-bottom: 0;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;

        .price {
          .old__price {
            margin-right: 5px;
            text-decoration: line-through;
            color: var(--red);
          }
          .new__price {
            color: var(--green);
            font-weight: 700;
          }
        }
        font-size: 13px;
        &.product__name {
          font-weight: 700;
          text-transform: uppercase;
          justify-content: center;
          text-align: center;
          border-bottom: 2px solid var(--green);
        }
        span {
          line-height: 13px;
          font-size: 12px;
          text-align: right;
        }
      }
    }
  }
  .product__botton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 20%;
    background: #fff;
    transform: translateY(100px);
    transition: 0.5s;
    will-change: transform;
  }
  &:hover .product__botton {
    transform: translateY(0);
  }
}
</style>
