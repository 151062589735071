<template>
  <div class="add__new__news__wrapper">
    <div class="add__new__news__title">
      <div class="back">
        <button class="btn btn-warning" @click="$emit('back')">
          <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
        </button>
      </div>
      <div class="title__news">
        <h4>Добавить новую запись</h4>
      </div>
    </div>
    <div class="add__news__form">
      <form @submit.prevent="func_save_news" class="form">
        <div class="form-group">
          <label>Заголовок</label>
          <input type="text" required class="form-control" v-model="news.title" />
        </div>
        <div class="form-group">
          <label>Текст</label>
          <vue-editor v-model="news.text"></vue-editor>
        </div>
        <div class="form-group">
          <label>Изображение</label>
          <input
            type="file"
            id="file"
            ref="file"
            v-on:change="func_file_upload()"
            required
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
          />
        </div>
        <button class="btn btn-info">Добавить</button>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  props: {
    news_data: Object,
  },
  data() {
    return {
      news: {
        title: "",
        text: "",
        img: "",
      },
    };
  },
  methods: {
    func_file_upload() {
      this.news.img = this.$refs.file.files[0];
    },
    func_save_news() {
      if (this.news.img || this.news.title || this.news.text) {
        window
          .sendRequest({
            func: "store_add_new_news",
            auth: true,
            params: {
              new_id: this.news.new_id || null,
              title: this.news.title,
              text: this.news.text,
              img: this.news.img,
            },
          })
          .then((result) => {
            if (result && result.status) {
              this.$notify({
                group: "foo",
                title: "Информация",
                text: "Выполнено успешно",
                type: "info",
              });
              this.$emit("save");
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Предупреждение",
          text: "Заполните все поля",
          type: "warn",
        });
      }
    },
  },
  mounted() {
    if (this.news_data) this.news = this.news_data;
  },
};
</script>

<style lang="scss">
.add__new__news__wrapper {
  .add__new__news__title {
    display: flex;
    .back {
      margin-right: 5px;
    }
  }
}
</style>
