<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Регистрация</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="auth_form form" @submit.prevent="func_register">
            <div class="form-group">
              <label for="login">Введите логин</label>
              <input
                type="text"
                id="login"
                required
                class="form-control"
                v-model="login"
              />
            </div>
            <div class="form-group">
              <label for="last_name">Введите вашу фамилию</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                required
                v-model="last_name"
              />
            </div>
            <div class="form-group">
              <label for="first_name">Введите ваше имя</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                required
                v-model="first_name"
              />
            </div>
            <div class="form-group">
              <label for="email">Введите ваш email адрес</label>
              <input
                type="email"
                class="form-control"
                id="email"
                required
                v-model="email"
              />
            </div>

            <div class="form-group">
              <label for="pass">Введите пароль</label>
              <input
                type="password"
                id="pass"
                required
                class="form-control"
                v-model="password"
              />
            </div>
            <div class="form-group">
              <label for="pass2">Подтвердите пароль еще раз</label>
              <input
                type="password"
                id="pass2"
                required
                class="form-control"
                v-model="password2"
              />
            </div>
            <button class="btn">Регистрация</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: "",
      password: "",
      password2: "",
      last_name: "",
      first_name: "",
      email: "",
    };
  },
  methods: {
    func_register() {
      if (
        this.login &&
        this.password &&
        this.password2 &&
        this.last_name &&
        this.first_name &&
        this.email
      ) {
        if (this.password2 === this.password) {
          window
            .sendRequest({
              func: "store_register_user",
              auth: false,
              params: {
                login: this.login,
                password: this.password,
                password2: this.password2,
                last_name: this.last_name,
                first_name: this.first_name,
                email: this.email,
              },
            })
            .then((result) => {
              if (result) {
                if (Number(result.code) === 1) {
                  this.$notify({
                    group: "foo",
                    title: "Предупрждение",
                    text: result.msg,
                    type: "warn",
                  });
                } else {
                  this.$store.commit("CHANGE_MODAL", {
                    show: false,
                    module: null,
                  });
                  setTimeout(() => {
                    this.$store.commit("CHANGE_MODAL", {
                      show: true,
                      module: "auth",
                    });
                  }, 200);
                }
              }
            });
        } else {
          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Пароли не совпадают",
            type: "warn",
          });
        }
      } else {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Заполните все поля",
          type: "warn",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth_form {
  p {
    text-align: center;
    font-size: 13px;
    &.regiter {
      cursor: pointer;
      font-size: 15px;
      color: var(--red);
    }
  }
  button {
    margin: 0 auto;
    display: block;
    background: var(--green);
    color: #fff;
    padding: 10px 30px;
    transition: 0.5s;
    &:hover {
      opacity: 0.8;
      color: #fff;
    }
  }
}
</style>