<template>
  <div>
    <Header />
    <CatalogRow :catalog="false" />
    <div class="catalog">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="catalog__title">
              <div class="back">
                <button class="btn btn-warning" @click="$router.push('/')">
                  <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                </button>
              </div>
              <div class="title">Каталог товаров</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <FilterCatalog @filter="func_filter" :pMin="pMin" :pMax="pMax" />
            <div class="catalog__title_down">
              <h5>Категории</h5>
            </div>
            <CatalogDrop @link="func_get_link" />
          </div>
          <div class="col-lg-9">
            <div class="catalog__path" v-if="menu_obj">
              <ul>
                <li>
                  {{ menu_obj.title }}
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="sort__block">
                <span>Сортировать по:</span>
                <select v-model="c_sort_select_id">
                  <option v-for="sort in sort_arr" :key="sort.id" :value="sort.id">
                    {{ sort.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row catalog__wrapper" v-if="products_list.length > 0">
              <div
                class="col-lg-4 col-md-4 col-sm-6"
                v-for="product in filtered_list"
                :key="product.product_id"
              >
                <ProductItem :product="product" />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-12">
                <div class="product__none">
                  <div>
                    <img src="@/assets/img/no_serch.jpg" alt="" />
                    <p>Товары не найдены</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import ProductItem from "@/components/v-product-item.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import CatalogDrop from "@/components/v-catalog-drop.vue";
import FilterCatalog from "@/components/v-filter.vue";
import Footer from "@/components/v-footer.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
    ProductItem,
    CatalogRow,
    CatalogDrop,
    FilterCatalog,
    Footer,
  },
  props: {
    menu: Object,
  },
  data() {
    return {
      menu_obj: null,
      products_list: [],
      filter: [],
      pMin: null,
      pMax: null,
      sort_arr: [
        { id: 1, value: "умолчанию" },
        { id: 2, value: "цене по возрастанию" },
        { id: 3, value: "цене по убыванию" },
        { id: 4, value: "производителю" },
        { id: 5, value: "скидке" },
      ],
      sort_select_id: 1,
    };
  },
  methods: {
    func_get_products(menu) {
      this.menu_obj = menu;
      this.products_list = [];
      window
        .sendRequest({
          func: "store_get_products",
          auth: false,
          params: {
            menu_id: menu ? menu.menu_id : null,
            submenu_id: menu ? menu.submenu_id : null,
          },
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.products_list = result;
          }
        });
    },
    func_get_link(link) {
      if (link) {
        this.menu_obj = link;
        this.func_get_products(link);
      }
    },
    func_filter(filter) {
      if (filter && filter.filter_checked) this.filter = filter.filter_checked;
      if (filter) {
        if (filter.pMax === 5000) {
          this.pMax = null;
        } else {
          this.pMax = filter.pMax;
        }
        if (filter.pMin === 0) {
          this.pMin = null;
        } else {
          this.pMin = filter.pMin;
        }
      }
    },
  },
  mounted() {
    if (this.menu) {
      this.func_get_products(this.menu);
      this.menu_obj = this.menu;
    } else {
      let menu = {
        menu_id: 9,
        title: "Скидки и акции",
      };
      this.func_get_products(menu);
      this.menu_obj = menu;
    }
  },
  watch: {
    CATALOG_SELECT_STATE() {
      this.func_get_products(this.CATALOG_SELECT_STATE);
    },
  },
  computed: {
    filtered_list: function () {
      let nal = null,
        order = null,
        sale = null;

      let pMin = this.pMin || 0;
      let pMax = this.pMax || 5000;

      if (this.filter) {
        nal = this.filter.find((item) => Number(item.id) === 1)
          ? this.filter.find((item) => Number(item.id) === 1).check
          : null;
        order = this.filter.find((item) => Number(item.id) === 2)
          ? this.filter.find((item) => Number(item.id) === 2).check
          : null;
        sale = this.filter.find((item) => Number(item.id) === 3)
          ? this.filter.find((item) => Number(item.id) === 3).check
          : null;
      }

      return this.products_list.filter((item) => {
        if (
          (sale &&
            (100 * (Number(item.old_price) - Number(item.new_price))) /
              Number(item.old_price) >
              0) ||
          (nal && item.order === false) ||
          (order &&
            item.order === true &&
            Number(item.new_price) > pMin &&
            Number(item.new_price) < pMax)
        ) {
          return true;
        } else if (
          !sale &&
          !nal &&
          !order &&
          Number(item.new_price) > pMin &&
          Number(item.new_price) < pMax
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    c_sort_select_id: {
      get() {
        return this.sort_select_id;
      },
      set(value) {
        switch (value) {
          case 1:
            this.products_list.sort((a, b) => {
              if (Number(a.product_id) > Number(b.product_id)) {
                return 1;
              } else {
                return -1;
              }
            });
            break;
          case 2:
            this.products_list.sort((a, b) => {
              if (Number(a.new__price) > Number(b.new__price)) {
                return 1;
              } else {
                return -1;
              }
            });
            break;
          case 3:
            this.products_list.sort((a, b) => {
              if (Number(a.new__price) < Number(b.new__price)) {
                return 1;
              } else {
                return -1;
              }
            });
            break;
          case 4:
            this.products_list.sort((a, b) => {
              if (a.fabr.toLowerCase() < b.fabr.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            });
            break;
          case 5:
            this.products_list.sort((a, b) => {
              let a_sale =
                (100 * (Number(a.old_price) - Number(a.new_price))) / Number(a.old_price);
              let b_sale =
                (100 * (Number(b.old_price) - Number(b.new_price))) / Number(b.old_price);
              if (a_sale > b_sale) {
                return -1;
              } else {
                return 1;
              }
            });
            break;
        }
        this.sort_select_id = value;
      },
    },
    ...mapGetters(["CATALOG_SELECT_STATE"]),
  },
};
</script>

<style lang="scss" scoped>
.catalog {
  .catalog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 4px solid var(--green);
    .title {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .catalog__path {
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-top: 10px;
      li {
        padding: 5px 10px;
        border: 1px solid #c2c2c2;
        border-radius: 5px;
        margin-left: 10px;
        transition: 0.5s;
        &:first-child {
          margin-left: 0;
        }
      }
      span {
        margin-left: 10px;
        font-weight: 700;
        font-family: "Tahoma";
      }
    }
  }
}
.catalog__title_down {
  margin-top: 10px;
}
.sort__block {
  display: flex;
  font-weight: 700;
  margin-left: 15px;
  margin-bottom: 10px;
  select {
    margin-left: 5px;
    border: 0;
    color: var(--red);
    &:focus {
      outline: none;
    }
  }
}
.catalog__wrapper {
  min-height: 420px;
}
.product__none {
  min-height: 420px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 200px;
  }
  p {
    text-align: center;
    margin-top: 5px;
    font-weight: 700;
    font-size: 16px;
  }
}
</style>
