<template>
  <div class="edit__product__wrapper">
    <h5>Редактировать товар</h5>
    <div class="edit__product__form">
      <form @submit.prevent="func_save_product" v-if="product">
        <div class="form-group">
          <label>Наименование</label>
          <input
            type="text"
            class="form-control"
            required
            v-model="product.product_name"
          />
        </div>
        <div class="form-group">
          <label>Артикул</label>
          <input type="text" class="form-control" required v-model="product.article" />
        </div>
        <div class="form-group">
          <label>Производитель</label>
          <input type="text" class="form-control" required v-model="product.fabr" />
        </div>
        <div class="form-group">
          <label>Описание</label>
          <textarea
            required
            v-model="product.description"
            class="form-control"
          ></textarea>
        </div>
        <div class="form-group">
          <label>Цена (старая цена: '{{ product.old_price || 0 }}')</label>
          <input
            type="number"
            class="form-control"
            required
            v-model="product.new_price"
          />
        </div>
        <div class="form-group">
          <label>Кол-во</label>
          <input
            type="number"
            class="form-control"
            required
            v-model="product.product_count"
          />
        </div>
        <div class="form-group">
          <input type="checkbox" v-model="product.to_order" />
          <label>Под заказ</label>
        </div>
        <button class="btn btn-success btn__edit">Изменить</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    func_save_product() {
      window
        .sendRequest({
          func: "store_edit_product",
          auth: false,
          params: this.product,
        })
        .then((result) => {
          if (result) {
            this.$emit("show_list");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn__edit {
  margin-bottom: 15px;
}
</style>
