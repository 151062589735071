<template>
  <div class="delivery__wrapper">
    <div class="delivery__list" v-if="delivery_list && delivery_list.length > 0">
      <ul>
        <li
          v-for="delivery in delivery_list"
          :key="delivery.user_adr_id"
          @click="func_select_adr(delivery)"
        >
          <div class="user__adr__item">
            <div class="user__adr__check">
              <input
                type="checkbox"
                readonly
                v-model="delivery.selected"
                class="form-control"
              />
            </div>
            <div class="user__adr__info">
              {{
                delivery.kvartira
                  ? delivery.index +
                    " г. " +
                    delivery.city +
                    " р-н. " +
                    delivery.rayon +
                    " ул. " +
                    delivery.street +
                    " д. " +
                    delivery.house +
                    " кв. " +
                    delivery.kvartira
                  : delivery.index +
                    " г. " +
                    delivery.city +
                    " р-н. " +
                    delivery.rayon +
                    " ул. " +
                    delivery.street +
                    " д. " +
                    delivery.house
              }}
            </div>
            <div class="user__adr__del">
              <button class="btn btn-danger" @click="func_remove_adr(delivery)">
                Удалить
              </button>
            </div>
          </div>
        </li>
      </ul>
      <button class="btn btn-success" v-show="!add_new_adr" @click="add_new_adr = true">
        Добавить новый адрес
      </button>
    </div>
    <div
      class="delivery__form"
      v-if="add_new_adr || !delivery_list || delivery_list.length === 0"
    >
      <form @submit.prevent="func_save_adr">
        <div class="form-group">
          <label>Город</label>
          <input type="text" class="form-control" required v-model="delivery_data.city" />
        </div>
        <div class="form-group">
          <label>Индекс</label>
          <input
            type="number"
            class="form-control"
            required
            v-model="delivery_data.index"
          />
        </div>
        <div class="form-group">
          <label>Район</label>
          <input
            type="text"
            class="form-control"
            required
            v-model="delivery_data.rayon"
          />
        </div>
        <div class="form-group">
          <label>Улица</label>
          <input
            type="text"
            class="form-control"
            required
            v-model="delivery_data.street"
          />
        </div>
        <div class="form-group">
          <label>Дом</label>
          <input
            type="text"
            class="form-control"
            required
            v-model="delivery_data.house"
          />
        </div>
        <div class="form-group">
          <label>Квартира</label>
          <input type="text" class="form-control" v-model="delivery_data.kvartira" />
        </div>
        <button class="btn btn-success">Сохранить адрес доставки</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delivery_list: [],
      add_new_adr: false,
      delivery_data: {
        city: "",
        index: "",
        rayon: "",
        street: "",
        house: "",
        kvartira: "",
      },
    };
  },
  methods: {
    func_get_adr() {
      window
        .sendRequest({
          func: "store_get_delivery_adr",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.delivery_list = result;
          }
        });
    },
    func_save_adr() {
      let delivery_data = this.delivery_data;
      if (
        delivery_data.city &&
        delivery_data.index &&
        delivery_data.rayon &&
        delivery_data.street &&
        delivery_data.house
      ) {
        window
          .sendRequest({
            func: "store_set_delivery_adr",
            auth: true,
            params: {
              city: delivery_data.city,
              index: delivery_data.index,
              rayon: delivery_data.rayon,
              street: delivery_data.street,
              house: delivery_data.house,
              kvartira: delivery_data.kvartira,
            },
          })
          .then((result) => {
            if (result && result.length > 0) {
              this.delivery_list = result;
              this.add_new_adr = false;
              delivery_data.city = delivery_data.index = delivery_data.rayon = delivery_data.street = delivery_data.house =
                "";
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Информация",
          text: "Заполните поля отмечанные *",
          type: "warn",
        });
      }
    },
    func_select_adr(delivery) {
      if (delivery) {
        this.delivery_list.forEach((item) => {
          if (item) {
            if (Number(item.user_adr_id) === Number(delivery.user_adr_id)) {
              this.add_new_adr = false;
              if (item.selected) {
                this.$emit("delivery_id", null);
                item.selected = false;
              } else {
                item.selected = true;
                this.$emit("delivery_id", delivery.user_adr_id);
              }
            } else {
              item.selected = false;
            }
          }
        });
      }
    },
    func_remove_adr(delivery) {
      if (delivery) {
        window
          .sendRequest({
            func: "store_set_delivery_adr_disable",
            auth: true,
            params: {
              user_adr_id: delivery.user_adr_id,
            },
          })
          .then((result) => {
            if (result && result.status) {
              this.delivery_list.splice(
                this.delivery_list.indexOf(
                  this.delivery_list.find((x) => x.user_adr_id === delivery.user_adr_id)
                ),
                1
              );
            }
          });
      }
    },
  },
  mounted() {
    this.func_get_adr();
  },
};
</script>

<style lang="scss">
.delivery__wrapper {
  .delivery__list {
    margin-bottom: 15px;
    ul {
      list-style-type: none;
      li {
        margin-bottom: 5px;
        .user__adr__item {
          border: 1px solid #c2c2c2;
          cursor: pointer;
          padding: 10px;
          align-items: center;
          display: flex;
          .user__adr__check {
            flex-basis: 10%;
          }
          .user__adr__info {
            flex-basis: 80%;
          }
        }
      }
    }
  }
  .delivery__form {
    margin-bottom: 10px;
  }
}
</style>
