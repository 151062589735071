<template>
  <div class="filter">
    <div class="catalog__filter">
      <div class="filter__title" @click="filter_show = !filter_show">
        <div class="count_filter">
          <span
            class="btn btn-success"
            v-show="func_calc_check() > 0"
            @click="func_clear_check()"
            >{{ func_calc_check() }}</span
          >Фильтр
        </div>
        <i class="fas fa-filter"></i>
      </div>
      <ul v-show="filter_show">
        <li v-for="filter in filter_arr" :key="filter.id">
          <div class="filter__check">
            {{ filter.title }}
            <input
              type="checkbox"
              class="form-control"
              v-model="filter.check"
            />
          </div>
        </li>
        <li>
          <div class="filter_check">
            {{ "Диапозон цен" }}
            <div class="range-slider">
              <input
                type="range"
                :min="0"
                :max="5000"
                step="10"
                v-model.number="minPrice"
                @change="func_check_range"
              />
              <input
                type="range"
                :min="0"
                :max="5000"
                step="10"
                v-model.number="maxPrice"
                @change="func_check_range"
              />
            </div>
          </div>
          <div class="filter__price">
            <span>Min: {{ minPrice }}</span>
            <span>Max: {{ maxPrice }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pMin: Number,
    pMax: Number,
  },
  data() {
    return {
      filter_show: false,
      filter_arr: [
        { id: 1, title: "В наличии", check: false },
        { id: 2, title: "Под заказ", check: false },
        { id: 3, title: "Со скидкой", check: false },
      ],
      minPrice: 0,
      maxPrice: 5000,
    };
  },
  methods: {
    func_calc_check() {
      let count = 0;
      let filter_checked = [];
      this.filter_arr.forEach((item) => {
        if (item.check === true) {
          count += 1;
          filter_checked.push(item);
        }
      });
      if (this.minPrice > 0 || this.maxPrice < 5000) {
        count += 1;
      }
      this.$emit("filter", {
        filter_checked: filter_checked,
        pMin: this.minPrice,
        pMax: this.maxPrice,
      });
      return count;
    },
    func_clear_check() {
      this.filter_arr.forEach((item) => {
        item.check = false;
      });
      this.minPrice = 0;
      this.maxPrice = 5000;
    },
    func_check_range() {
      this.func_calc_check();
      if (this.minPrice > this.maxPrice) {
        let tmp = this.maxPrice;
        this.maxPrice = this.minPrice;
        this.minPrice = tmp;
      }
      let filter_checked = [];
      this.filter_arr.forEach((item) => {
        if (item.check === true) {
          filter_checked.push(item);
        }
      });
      this.$emit("filter", {
        filter_checked: filter_checked,
        pMin: this.minPrice,
        pMax: this.maxPrice,
      });
    },
  },
};
</script>

<style lang='scss'>
.catalog__filter {
  margin-bottom: 10px;
  margin-top: 10px;
  .filter__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e2e2e2;
    padding: 10px;
    cursor: pointer;
    .count_filter {
      span {
        margin-right: 5px;
        color: #fff;
        position: relative;
        transition: 0.5s;
        &:hover:before {
          opacity: 1;
        }
        &:before {
          transition: 0.3s;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--red);
          content: "X";
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          opacity: 0;
        }
      }
    }
  }
  ul {
    list-style-type: none;
    li {
      padding: 5px;
      .filter__check {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        input {
          width: 20px;
          height: 20px;
          cursor: pointer;
          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .range-slider {
      position: relative;
      width: 100%;
      margin: 16px auto;
      text-align: center;
      svg,
      input[type="range"] {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        &:focus {
          outline: none;
        }
        -webkit-appearance: none;
      }
      input[type="range"]::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        top: 2px;
        margin-top: -7px;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
      }
    }
    .filter__price {
      display: flex;
      font-size: 13px;
      padding-top: 5px;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>