<template>
  <div class="submenu">
    <li>
      <a @click="func_a(item)">
        <div class="menu__item">
          <div class="menu__item__img">
            <img v-if="item.img" :src="'/img/menu/' + item.img" alt="" />
            {{ item.title }}
          </div>
          <div class="menu__item__icon" v-show="item.submenu && item.submenu.length > 0">
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </div>
      </a>
      <DropItem v-for="(menu, index) in item.submenu" :key="index" :item="menu" />
    </li>
  </div>
</template>

<script>
export default {
  name: "DropItem",
  props: {
    item: Object,
  },
  methods: {
    func_a(item) {
      this.$store.commit("CHANGE_CATALOG_SELECT", item);
      if (this.$router.history.current.name === "admin") return;
      if (this.$router.history.current.name !== "catalog") {
        this.$router.push("/catalog");
      }
      this.$route.params.menu = item;
    },
  },
};
</script>

<style>
.menu__item__icon {
  padding: 0 5px;
}
</style>
