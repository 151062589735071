<template>
  <div class="promo_list">
    <div class="promo__title">
      <h4>Промокоды</h4>
      <div class="add_promo">
        <h5>Добавить промокод</h5>
        <form @submit.prevent="func_add_new_promo">
          <div class="form-group">
            <label>Промокод</label>
            <input
              type="number"
              required
              v-model="new_promo.promo"
              maxlength="6"
              minlength="6"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Скидка</label>
            <input
              type="number"
              class="form-control"
              required
              v-model="new_promo.sale"
              maxlength="2"
            />
          </div>
          <div class="form-group">
            <input type="checkbox" v-model="new_promo.active" />
            <label>Активный</label>
          </div>
          <button class="btn btn-success">Добавить</button>
        </form>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>ID промокода</th>
            <th>Промокод</th>
            <th>Скидка</th>
            <th>Активен</th>
            <th>Удален</th>
          </tr>
        </thead>
        <tbody v-if="promo_list.length">
          <tr v-for="promo in promo_list" :key="promo.promo_id">
            <td>{{ promo.promo_id }}</td>
            <td>{{ promo.promo }}</td>
            <td>{{ promo.sale }}%</td>
            <td>
              <input
                type="checkbox"
                v-model="promo.active"
                @change="func_save_promo(promo)"
              />
            </td>
            <td>
              <input
                type="checkbox"
                v-model="promo.disable"
                @change="func_save_promo(promo)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      promo_list: [],
      new_promo: {
        promo: "",
        sale: "",
        active: true,
      },
    };
  },
  methods: {
    func_get_promo_list() {
      window
        .sendRequest({
          func: "store_get_promo_list",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.promo_list = result;
          }
        });
    },
    func_save_promo(promo) {
      if (promo) {
        window.sendRequest({
          func: "store_set_promo",
          auth: true,
          params: promo,
        });
      }
    },
    func_add_new_promo() {
      let promo = this.new_promo;
      if (promo.promo && promo.sale) {
        if (Number(promo.sale) > 100 || Number(promo.sale) < 1) {
          this.$notify({
            group: "foo",
            title: "Информация",
            text: "Введите корректную скидку",
            type: "warn",
          });
          return;
        }
        if (promo.promo.length !== 5) {
          this.$notify({
            group: "foo",
            title: "Информация",
            text: "Промокод должен быть из 5 символов",
            type: "warn",
          });
          return;
        }
        window
          .sendRequest({
            func: "store_set_add_promo",
            auth: true,
            params: promo,
          })
          .then((result) => {
            if (result) {
              if (result.status) {
                this.promo_list.push(result);

                this.new_promo.promo_id = null;
                this.new_promo.sale = "";
                this.new_promo.promo = "";
              } else {
                this.$notify({
                  group: "foo",
                  title: "Информация",
                  text: "Такой промокод уже существует",
                  type: "warn",
                });
              }
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Информация",
          text: "Заполните поля",
          type: "warn",
        });
      }
    },
  },
  mounted() {
    this.func_get_promo_list();
  },
};
</script>

<style></style>
