<template>
  <div class="products_select__wrapper">
    <div class="products__select__input">
      <div class="product__input__wrapper">
        <input
          placeholder="Найти продукт"
          class="form-control"
          type="text"
          v-model="search_val"
          v-if="!select_product"
          @keyup.enter="func_add_product_enter"
        />
        <input
          placeholder="Найти продукт"
          class="form-control"
          type="text"
          readonly
          :value="select_product.product_name"
          v-if="select_product"
          @dblclick="func_edit_filter"
        />
        <div class="product__filter" v-if="filter_list.length > 0">
          <ul>
            <li
              @click="func_add_product(filter)"
              v-for="filter in filter_list"
              :key="filter.product_id"
            >
              <div class="filter__img__product">
                <img
                  :src="filter.img ? '/img/products/' + filter.img : '/img/fallback.png'"
                  alt=""
                />
              </div>
              <div class="product__title">
                <span><i>Наименование: </i>{{ filter.product_name }}</span>
                <span><i>Цена: </i>{{ filter.new_price || 0 }} руб.</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btn-info" @click="func_add_product_return">Добавить</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    week_number: Number,
  },
  data() {
    return {
      products_list: [],
      select_product: null,
      search: "",
      filter_list: [],
    };
  },
  methods: {
    func_get_product_list() {
      window
        .sendRequest({
          func: "store_get_product_list",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.products_list = result.filter((x) => x.disable === false);
          }
        });
    },
    func_add_product(product) {
      if (!product) {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Товар не выбран",
          type: "warn",
        });
      } else {
        this.filter_list = [];
        this.select_product = product;
      }
    },
    func_edit_filter() {
      this.search_val = this.select_product.product_name;
      this.select_product = null;
    },
    func_add_product_enter() {
      if (this.filter_list.length === 1) {
        this.select_product = this.filter_list[0];
        this.filter_list = [];
      }
    },
    func_add_product_return() {
      if (this.select_product) {
        this.$emit("add", {
          product: this.select_product,
          week_number: this.week_number,
        });
        this.select_product = null;
        this.filter_list = [];
        this.search_val = "";
      } else {
        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Товар не выбран",
          type: "warn",
        });
      }
    },
  },
  mounted() {
    this.func_get_product_list();
  },
  computed: {
    search_val: {
      get() {
        return this.search;
      },
      set(value) {
        if (value) {
          this.filter_list = this.products_list.filter((x) =>
            x.product_name
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          );
        } else {
          this.filter_list = [];
        }
        this.search = value;
      },
    },
  },
};
</script>

<style lang="scss">
.products_select__wrapper {
  .products__select__input {
    width: 100%;
    display: flex;
    button {
      margin-left: 5px;
    }
    .product__input__wrapper {
      position: relative;
      width: 100%;
    }
    .product__filter {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 999;
      right: 0;
      background: #fff;
      border: 1px solid #e2e2e2;
      box-shadow: 0px 0px 7px 0px black;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          transition: 0.5s;
          border-bottom: 1px solid #c2c2c2;
          padding: 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            width: 100%;
            display: block;
          }
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: var(--green);
            color: #fff;
          }
          .filter__img__product {
            width: 50px;
            margin-right: 5px;
            height: 50px;
            img {
              width: 100%;
              height: 100%;
              display: flex;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}
</style>
