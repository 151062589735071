<template>
  <div>
    <ul class="mainmenu">
      <DropItem v-for="(item, index) in menu_list" :key="index" :item="item" />
    </ul>
  </div>
</template>

<script>
import DropItem from "@/components/v-catalog-drop-item.vue";
export default {
  components: {
    DropItem,
  },
  data() {
    return {
      menu_list: [],
    };
  },
  methods: {
    func_get_data() {
      window
        .sendRequest({
          func: "store_get_category",
          auth: false,
          params: {},
        })
        .then((result) => {
          if (result && result.length > 0) {
            const tree = (array) =>
              array
                .reduce((a, c) => {
                  c.submenu = array.filter((i) => i.menu_parent_id == c.menu_id);
                  a.push(c);
                  return a;
                }, [])
                .filter((i) => i.menu_parent_id === 0);
            this.menu_list = tree(result);
          }
        });
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style lang="scss">
.mainmenu,
.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
}
.mainmenu {
  li {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #000;
    transition: 0.6s;
    will-change: transform;
  }
  .menu__item {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #c2c2c2;
    justify-content: space-between;
    align-items: center;
    .menu__item__img {
      display: flex;
      align-items: center;
      img {
        height: 40px;
        width: 70px;
        margin-right: 5px;
        display: flex;
        object-fit: cover;
        object-position: center;
      }
    }
    &:hover {
      background-color: var(--green);
      color: #fff;
    }
  }
  li {
    cursor: pointer;

    &:hover > .submenu {
      display: block;
      max-height: 200px;
    }
    .submenu {
      will-change: transform;
      transition: 1s;
      overflow: hidden;
      max-height: 0;
      li {
        padding-left: 15px;
      }
    }
  }
}

.mainmenu > .submenu {
  overflow: visible;
}
</style>
