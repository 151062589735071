<template>
  <div class="products__order__wrapper" v-if="products && products.length > 0">
    <div class="products__order__title">
      <h4>Мои заказанные товары</h4>
    </div>
    <div class="products__order__table">
      <table class="table">
        <thead>
          <th>ID товара</th>
          <th>Наименование</th>
          <th>Артикул</th>
          <th>Тип</th>
          <th>Статус заявки</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.product_id">
            <td>{{ product.product_id }}</td>
            <td>{{ product.product_name }}</td>
            <td>{{ product.article }}</td>
            <td>{{ product.order ? "Под заказ" : "В наличии" }}</td>
            <td>{{ product.status }}</td>
            <td>
              <button
                v-if="!product.order"
                class="btn btn-success"
                title="В корзину"
                @click="func_add_to_cart(product)"
              >
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      products: [],
    };
  },
  methods: {
    func_get_products_order() {
      window
        .sendRequest({
          func: "store_get_products_order",
          auth: true,
          params: {},
        })
        .then((result) => {
          if (result) {
            this.products = result;
          }
        });
    },
    func_add_to_cart(product) {
      if (product) {
        let cart = [...this.CART_STATE];
        if (cart.length > 0) {
          let obj = cart.find((item) => item.product_id === product.product_id);
          if (obj) {
            this.$notify({
              group: "foo",
              title: "Предупрждение",
              text: "Товар уже в корзине",
              type: "warn",
            });
            return;
          }
        }
        cart.push(product);
        this.$store.commit("CHANGE_CART", cart);

        localStorage.setItem("cart", JSON.stringify(cart));

        this.$notify({
          group: "foo",
          title: "Предупрждение",
          text: "Товар добавлен в корзину",
          type: "info",
        });
      }
    },
  },
  mounted() {
    this.func_get_products_order();
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
  },
};
</script>

<style lang="scss">
.products__order__wrapper {
  .products__order__title {
    padding-bottom: 5px;
    border-bottom: 3px solid var(--green);
    h4 {
      text-transform: uppercase;
      font-size: 18px;
      color: var(--green);
      font-weight: 700;
    }
  }
}
</style>
