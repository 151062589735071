<template>
  <div class="product__wrapper">
    <Header />
    <CatalogRow :catalog="true" />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="back">
            <button class="btn btn-warning" @click="$router.push('/catalog')">
              <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="product" v-if="product">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="product__img">
              <ZoomImage
                :src="product.img ? '/img/products/' + product.img : '/img/fallback.png'"
              />
            </div>
            <ProductImg :product_id="product_id_comment" />
          </div>
          <div class="col-lg-8">
            <div class="product__description">
              <h3>
                {{ product.product_name }}
                <div class="buttons">
                  <button
                    class="btn btn-warning"
                    @click="func_add_product(false, product)"
                    title="В список"
                  >
                    <i class="fas fa-star" aria-hidden="true"></i>
                  </button>
                  <button
                    class="btn btn-success"
                    @click="func_add_product(true, product)"
                    title="В корзину"
                  >
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </button>
                </div>
              </h3>
              <ul>
                <li>
                  Артикул: <span>{{ product.article }}</span>
                </li>
                <li>
                  Производитель: <span>{{ product.fabr }}</span>
                </li>
                <li>
                  Вес: <span>{{ product.weight }} грамм</span>
                </li>
                <li>
                  Цена<span
                    ><div class="price">
                      <span
                        class="old__price"
                        v-show="Number(product.old_price) < Number(product.new_price)"
                        >{{ product.old_price }} руб.</span
                      >
                      <span class="new__price">{{ product.new_price }} руб.</span>
                    </div></span
                  >
                </li>
                <li>
                  Статус товара:
                  <span>{{ product.to_order ? "Под заказ" : "В наличии" }}</span>
                </li>
                <li>
                  Кол-во заказов: <span>{{ product.order_count || 0 }}</span>
                </li>
              </ul>
              <div class="description">
                <h5>Описание</h5>
                <p>{{ product.description }}</p>
              </div>
              <Comment :product_id="product_id_comment" />
            </div>
          </div>
        </div>
        <div class="row" v-if="product_list_arr && product_list_arr.length > 0">
          <div class="col-lg-12">
            <h5 class="dop_tovar">Товары из этой категории</h5>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-3"
            v-for="product in product_list_arr"
            :key="product.product_id"
          >
            <ProductItem :product="product" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import ZoomImage from "@/components/v-zoom-img.vue";
import ProductItem from "@/components/v-product-item.vue";
import Footer from "@/components/v-footer.vue";
import Comment from "@/components/v-comment-product.vue";
import ProductImg from "@/components/v-product-img.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
    CatalogRow,
    ZoomImage,
    ProductItem,
    Footer,
    Comment,
    ProductImg,
  },
  props: {
    id: String,
  },

  data() {
    return {
      product: {},
      product_list_arr: [],
      product_id_comment: null,
    };
  },
  methods: {
    func_get_data(product_id) {
      if (product_id) {
        this.product_id_comment = product_id;
        window
          .sendRequest({
            func: "store_get_product",
            auth: localStorage.getItem("user") ? true : false,
            params: {
              product_id: product_id,
            },
          })
          .then((result) => {
            if (result && result.product) {
              this.product = result.product;
            }
            if (result && result.product_list) {
              this.product_list_arr = result.product_list;
            }
          });
      }
    },
    func_show_product() {
      this.$router.push("/product/" + Number(8).toString());
    },
    func_add_product(cart_bool, product) {
      if (product) {
        if (cart_bool) {
          let cart = [...this.CART_STATE];
          if (cart.length > 0) {
            let obj = cart.find((item) => item.product_id === product.product_id);
            if (obj) {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар уже в корзине",
                type: "warn",
              });
              return;
            }
          }
          cart.push(product);
          this.$store.commit("CHANGE_CART", cart);

          localStorage.setItem("cart", JSON.stringify(cart));

          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Товар добавлен в корзину",
            type: "info",
          });
        } else {
          let favor = [...this.FAVORITES_STATE];
          if (favor.length > 0) {
            let obj = favor.find((item) => item.product_id === product.product_id);
            if (obj) {
              this.$notify({
                group: "foo",
                title: "Предупрждение",
                text: "Товар уже в избранном",
                type: "warn",
              });
              return;
            }
          }
          favor.push(product);
          this.$store.commit("CHANGE_FAVORITES", favor);
          localStorage.setItem("favor", JSON.stringify(favor));

          this.$notify({
            group: "foo",
            title: "Предупрждение",
            text: "Товар добавлен в избранное",
            type: "info",
          });
        }
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (Number(from.params.id) !== Number(to.params.id)) {
      this.func_get_data(Number(to.params.id));
    }
    next();
  },
  mounted() {
    if (this.$route.params.id) {
      this.func_get_data(Number(this.$route.params.id));
    }
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
    ...mapGetters(["FAVORITES_STATE"]),
  },
};
</script>

<style lang="scss" scoped>
.product {
  background: #f7f7f7;
  padding-top: 20px;
  margin-top: 10px;
  .product__img {
    height: 250px;
    div {
      height: 100%;
    }
  }
  .product__description {
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .buttons {
        button {
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
    ul {
      list-style-type: none;
      width: 100%;
      border-bottom: 3px solid var(--green);
      li {
        font-size: 13px;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: 700;
          color: #222;
        }
      }
    }
    .description {
      font-size: 13px;
    }
  }
}
.dop_tovar {
  margin-top: 15px;
}
</style>
