<template>
  <div class="index__page">
    <Header />
    <CatalogRow :catalog="true" />
    <section class="header__soc">
      <div class="container">
        <div class="row">
          <div class="header__soc__wrapper">
            <ul>
              <li>
                <a href="#"><i class="fab fa-instagram" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-vk" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-facebook" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-odnoklassniki" aria-hidden="true"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <News />
    <Actions />
    <Footer />
  </div>
</template>

<script>
import News from "@/components/v-news.vue";
import Actions from "@/components/v-actions.vue";
import Header from "@/components/v-header.vue";
import CatalogRow from "@/components/v-catalog-row.vue";
import Footer from "@/components/v-footer.vue";
export default {
  components: {
    News,
    Actions,
    Header,
    CatalogRow,
    Footer,
  },
  data() {
    return {
      catalog_menu_show: false,
      right_menu_show: false,
      right_menu_cart: null,
      modal_show: false,
      modal_module: null,
    };
  },
  methods: {},
};
</script>

<style lang="scss"></style>
